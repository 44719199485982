.hashtags {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-bottom: 24px;

  span {
    font-size: 12px;
    color: #116b9b;
    background-color: #dceff3;
    padding: 4px 8px;
    border-radius: 4px;
  }
}
