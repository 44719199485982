.d-block {
	display: block !important;
}
.d-none {
	display: none !important;
}
.d-inline-block {
	display: inline-block !important;
}
.d-flex {
	display: flex !important;
}
.display-grid {
	display: grid;
}

.flex-row {
	flex-direction: row;
}

.flex-column {
	flex-direction: column;
}

.flex-column-reverse {
	flex-direction: column-reverse;
}

.flex-wrap {
	flex-wrap: wrap;
}

.flex-nowrap {
	flex-wrap: nowrap;
}

.justify-content-center {
	justify-content: center;
}

.justify-content-center {
	justify-content: center;
}

.flex-row-ms-reverse {
	flex-direction: row-reverse;
}
@media screen and (min-width: 768px) {
	.d-md-block {
		display: block !important;
	}
	.flex-md-row {
		flex-direction: row !important;
	}
	.d-md-none {
		display: none !important;
	}
	.d-md-inline-block {
		display: inline-block !important;
	}
	.d-md-flex {
		display: flex !important;
	}
}
.flex-row-reverse {
	flex-direction: row-reverse;
}
@media screen and (min-width: 992px) {
	.d-lg-block {
		display: block !important;
	}
	.d-lg-none {
		display: none !important;
	}
	.d-lg-inline-block {
		display: inline-block !important;
	}
	.d-lg-flex {
		display: flex !important;
	}
	.d-lg-flex-row {
		flex-direction: row !important;
	}
	.lg-flex-wrap {
		flex-wrap: wrap !important;
	}
}
@media screen and (min-width: 1200px) {
	.d-xl-block {
		display: block !important;
	}
	.d-xl-none {
		display: none !important;
	}
	.d-xl-inline-block {
		display: inline-block !important;
	}
	.d-xl-flex {
		display: flex !important;
	}
}
