.time {
	width: 242px;
	height: 130px;
	& div {
		width: calc(100% / 3);
	}
}

.time-container {
	height: 180px;
	overflow: auto;
	scroll-snap-type: y mandatory;
	z-index: 1;
}

.time-section {
	scroll-snap-align: start;
	min-height: 36px;
}

.bar-time {
	top: 43%;
	border-top: $secondPrimary70 solid 2px;
	border-bottom: $secondPrimary70 solid 2px;
	left: 5%;
	width: 90%;
	padding: 5% 0;
}
