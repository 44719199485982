@font-face {
	font-family: 'Roboto-Regular';
	src: url(../assets/fonts/Roboto-Regular.ttf);
	font-display: block;
}
@font-face {
	font-family: 'Roboto-Bold';
	src: url(../assets/fonts/Roboto-Bold.ttf);
	font-display: block;
}
@font-face {
	font-family: 'Roboto-Medium';
	src: url(../assets/fonts/Roboto-Medium.ttf);
	font-display: block;
}
@font-face {
	font-family: 'Roboto-Thin';
	src: url(../assets/fonts/Roboto-Thin.ttf);
	font-display: block;
}
@font-face {
	font-family: 'Roboto-Light';
	src: url(../assets/fonts/Roboto/Roboto-Light.ttf);
	font-display: block;
}

@font-face {
	font-family: 'Roboto-Italic';
	src: url(../assets/fonts/Roboto/Roboto-Italic.ttf);
	font-display: block;
}
