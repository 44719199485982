.sidebar-community {
  background-color: white;
  // overflow-y: scroll;
  @include dropShadow();
  &__logo {
    width: 150px;
  }

  &__header {
    border-bottom: 1px solid $firstNeutral30;
  }

  &__icon {
    width: 32px;
  }
}
// .h-vh{
//   height: 105vh;

// }
@media screen and (min-width: 992px) {

  .h-vh{
  height: 114vh;

}
}

@media screen and (min-width: 1600px) {
  .sidebar-community {
    height: 100%;
  }
}
