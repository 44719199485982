.w-100 {
	width: 100% !important;
}
.w-80 {
	width: 80% !important;
}
.w-83 {
	width: 83% !important;
}

.w-90 {
	width: 90%;
}
.w-70 {
	width: 70%;
}

.w-75 {
	width: 75%;
}

.w-66 {
	width: 66%;
}

.w-65 {
	width: 65%;
}

.w-60 {
	width: 60%;
}

.w-55 {
	width: 55%;
}

.w-50 {
	width: 50%;
}

.w-46 {
	width: 46%;
}

.w-45 {
	width: 45%;
}

.w-40 {
	width: 40%;
}

.w-48 {
	width: 48%;
}
.w-35 {
	width: 35%;
}
.w-30 {
	width: 30% !important;
}

.w-25 {
	width: 25%;
}

.w-20 {
	width: 20% !important;
}

.w-10 {
	width: 10%;
}

.w-8 {
	width: 8% !important;
}

.w-6 {
	width: 6% !important;
}

.w-5 {
	width: 5% !important;
}

.w-1 {
	width: 11px;
}

.w-auto {
	width: auto;
}
.w-100px {
	width: 100px;
}

.w-109 {
	width: 109px;
}

@media screen and (min-width: 768px) {
	.w-md-100 {
		width: 100% !important;
	}
	.w-md-50 {
		width: 50% !important;
	}
	.w-md-30 {
		width: 30% !important;
	}
	.w-md-60 {
		width: 60% !important;
	}
	.w-md-90 {
		width: 90% !important;
	}
	.w-500 {
		width: 500px;
	}
	.w-125 {
		width: 125px;
	}
	.h-500 {
		height: 500px;
	}

	.w-md-30 {
		width: 30%;
	}

	.w-md-20 {
		width: 10%;
	}
}

@media screen and (min-width: 992px) {
	.w-lg-90 {
		width: 90% !important;
	}

	.w-lg-80 {
		width: 80% !important;
	}

	.w-lg-60 {
		width: 60% !important;
	}

	.w-lg-50 {
		width: 50% !important;
	}
	.w-500 {
		width: 500px;
	}
	.h-500 {
		height: 500px;
	}

	.w-lg-30 {
		width: 30% !important ;
	}
}

@media screen and (min-width: 1000px) {
	.w-xl-60 {
		width: 55% !important;
	}
	.w-xl-40 {
		width: 40% !important;
	}
}
