.sun-editor {
	font-family: 'Roboto-Regular' !important;
}

.image-preview-blog-container2-md {
	height: 100px !important;
	width: 100% !important;
	display: flex;
	justify-content: center;
	align-items: center;
}

.container-image-blog {
	width: 230px;
	max-height: 180px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #c5e4ea;
	border-radius: 15px !important;
}
.subcontainer-image-blog {
	width: 100%;
	height: 100%;
}

.subcontainer-image-blog-card {
	width: 120px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #c5e4ea;
	border-radius: 15px !important;
}

.image-blog-list {
	width: 100%;
	height: 100%;
	object-fit: fill;
	border-radius: 15px;
}

.image-blog-card {
	width: 100%;
	height: 100%;
	max-width: 190px;
	max-height: 100px;
	object-fit: fill;
	margin: auto;
	border-radius: 15px;
}

.sun-editor button {
	color: $firstPrimary70 !important;
}

.sun-editor .se-toolbar {
	background-color: white !important;
}
.se-wrapper-inner .se-wrapper-wysiwyg .sun-editor-editable {
	line-height: 2.5 !important;
}
.card-blog-home {
	height: 170px;
	overflow: hidden;
}
.card-vertical-blog-home {
	height: 320px !important;
	overflow: hidden;
}
.image-preview-blog-container-home {
	width: 130px;
	height: 170px;
	border-radius: 15px !important;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #c5e4ea;
}
.sun-editor {
	border: none !important;
	color: $firstPrimary70 !important;
}

.sun-editor .se-dialog button,
.sun-editor .se-dialog input,
.sun-editor .se-dialog label {
	color: $firstPrimary70 !important;
}

.se-dialog-footer > button {
	background-color: $firstPrimary70 !important;
	color: white !important;
	border: none !important;
}

.se-btn-primary > span {
	color: white !important;
}
.sun-editor .se-wrapper .se-wrapper-inner {
	height: 1000px !important;
	border: 1px solid $firstPrimary70 !important;
	border-radius: 20px;
}

.se-toolbar {
	margin-bottom: 34px !important;
	padding-bottom: 15px !important;
}
.sun-editor .se-list-inner .se-list-basic li button.active {
	background-color: #c5e4ea !important;
}

.sun-editor input:focus,
.sun-editor select:focus,
.sun-editor textarea:focus {
	box-shadow: none !important;
	border: 1px solid $firstPrimary70 !important;
}

.sun-editor .se-toolbar {
	outline: none !important;
	border-bottom: 1px solid $firstNeutral60 !important;
}

.sun-editor-editable h1,
.sun-editor-editable h2,
.sun-editor-editable h3,
.sun-editor-editable h4 {
	text-align: center;
}
.se-resizing-bar {
	display: none !important;
}

.container-blog-preview > strong {
	font-weight: normal !important;
}

.card-blog-height {
	height: 120px;
	overflow: hidden;
}
.container-blog-preview > h1,
h2,
h3,
h4,
h5 {
	margin-top: 8px;
	margin-bottom: 10px;
	font-family: $robotoRegular;
}
.container-blog-preview > img {
	display: none;
}
.card-vertical-blog {
	height: 100px;
}

.container-blog-preview,
.blog-detail-container {
	font-family: $robotoRegular !important;
	line-height: 1.5 !important;
	background-color: white !important;
	color: $secondNeutral60 !important;
}

.sun-editor .se-btn:enabled:active {
	box-shadow: inset 0 3px 5px #c1c1c1;
}

.sun-editor .se-btn:enabled.active {
	outline: 0 none;
	background-color: #c5e4ea;
}
.container-blog-preview span,
p > span {
	background-color: white !important;
}
.container-blog-preview span,
p > strong {
	font-weight: normal !important;
}
.sun-editor .se-btn-list:disabled,
.sun-editor .se-btn:disabled,
.sun-editor button:disabled {
	cursor: not-allowed;
	background-color: inherit;
	color: #bdbdbd !important;
}
.icon-blog-clock-detail {
	height: 14px;
	width: 14px;
}

.se-wrapper > textarea::placeholder {
	text-align: center !important;
}
.blog-detail-container > h2:nth-child(1) {
	color: red !important;
	display: none;
}
.blog-detail-container > h1:nth-child(1) {
	color: red !important;
	display: none;
}
.blog-detail-container > table {
	display: table;
	table-layout: auto !important;
	border: 1px solid #ccc;
	width: 100%;
	max-width: 100%;
	margin: 0 0 10px;
	background-color: transparent;
	border-spacing: 0;
	border-collapse: collapse;
}

.blog-detail-container > td,
th {
	border: 1px solid #e1e1e1;
	padding: 0.4em;
	background-clip: padding-box;
}

.blog-detail-container > ol,
li {
	margin: 0px 10px;
}
.container-blog-preview > pre {
	display: block !important;
	padding: 8px !important;
	margin: 0 0 10px !important;
	font-family: monospace !important;
	color: #666 !important;
	line-height: 1.45 !important;
	background-color: #f9f9f9 !important;
	border: 1px solid #e1e1e1 !important;
	border-radius: 2px !important;
	white-space: pre-wrap !important;
	word-wrap: break-word !important;
	overflow: visible !important;
}
.blog-detail-container > pre {
	display: block !important;
	padding: 8px !important;
	margin: 0 0 10px !important;
	font-family: monospace !important;
	color: #666 !important;
	line-height: 1.45 !important;
	background-color: #f9f9f9 !important;
	border: 1px solid #e1e1e1 !important;
	border-radius: 2px !important;
	white-space: pre-wrap !important;
	word-wrap: break-word !important;
	overflow: visible !important;
}
.blog-detail-container > blockquote {
	display: block !important;
	font-family: inherit !important;
	font-size: inherit !important;
	color: #999 !important;
	margin-block-start: 1em !important;
	margin-block-end: 1em !important;
	margin-inline-start: 0 !important;
	margin-inline-end: 0 !important;
	padding: 0 5px 0 20px !important;
	border: solid #b1b1b1 !important;
	border-width: 0 0 0 5px !important;
}

.card-blog-height-vertical {
	height: 120px;
	overflow: hidden;
	width: 220px !important;
}
.card-vertical-blog {
	height: 330px !important;
	width: 220px !important;
	overflow: hidden;
}
@media screen and (min-width: 768px) {
	.card-blog-height {
		height: 130px;
		overflow: hidden;
		width: 350px !important;
	}
	.card-blog-height-vertical {
		height: 120px;
		overflow: hidden;
		width: 240px !important;
	}
	.card-vertical-blog {
		height: 330px !important;
		width: 245px !important;
	}
}

@media screen and (min-width: 992px) {
	.card-blog-height {
		height: 120px;
		overflow: hidden;
		width: 400px !important;
	}
	.card-blog-height-vertical {
		height: 120px;
		overflow: hidden;
		width: 240px !important;
	}
	.card-vertical-blog {
		height: 330px !important;
		width: 245px !important;
	}
}

@media screen and (min-width: 1100px) {
	.card-blog-height {
		height: 120px;
		overflow: hidden;
		width: 450px !important;
	}
	.card-blog-height-vertical {
		height: 120px;
		overflow: hidden;
		width: 200px !important;
	}

	.card-vertical-blog {
		height: 330px !important;
		width: 200px !important;
	}
}

.icon-detail-user {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	object-fit: cover;
}

@media screen and (max-width: 768px) {
	.icon-detail-user {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		object-fit: cover;
	}
}
.blog-comment {
	@include dropShadow();
	border-radius: 5px;
	background-color: $thirdNeutral;
}
