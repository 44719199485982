.hr-sidebar {
	border-bottom: 1px solid $firstNeutral30;
}

.hr-nav {
	border-bottom: 2px solid $firstNeutral30;
}

.hr-gray {
	border-bottom: 1px solid $gray;
}

.hr-ocean {
	border-bottom: 2px solid $Ocean30;
}

.hr-ocean-1 {
	border-bottom: 1px solid $Ocean30;
}

.hr-ocean-2 {
	width: 174px;
	border-bottom: 1px solid $Ocean30;
}

.hr-modal {
	border-bottom: 3px solid $firstNeutral50 !important;
}

.hr-pinkish-grey {
	border-bottom: 1px solid $firstNeutral40;
}
