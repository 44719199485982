.button-container {
	border-bottom: 1px solid $firstNeutral90;
}

.card-list-user {
	width: 100%;
}

.container-user {
	border-radius: 5px;
	box-shadow: -1px 4px 20px 3px rgba(0, 0, 0, 0.06);
	background-color: $thirdNeutral;
}
.height-tool {
	height: 150px;
}
.d-content {
	display: contents;
}
.tooltip {
	height: 120px;
	background-color: $thirdNeutral;
}
.Content_Container__xhjD8 {
	width: 200px !important;
}

.MuiIconButton-label {
	justify-content: space-between !important;
}
@media screen and (min-width: 768px) {
	.card-list-user {
		height: 66px;
	}
	.trans {
		transform: translateX(700%);
	}
}
@media screen and (min-width: 1024px) {
	.card-list-user {
		height: 66px;
	}
	.trans {
		transform: translateX(1100%);
	}
}
@media screen and (min-width: 1200px) {
	.card-list-user {
		height: 66px;
	}
	.trans {
		transform: translateX(600%);
	}
}
