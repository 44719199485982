.background-gray {
	background-color: $firstNeutral20;
	height: 100vh;
}

@media screen and (min-width: 992px) {
	.container {
		height: calc(100vh - 79px);
		overflow-y: scroll;
	}
}

@media screen and (min-width: 992px) {
	.container-report-list {
		height: calc(100vh - 208px);
		overflow-y: scroll;
	}
}
