.scroll-overflow {
	overflow: scroll;
}

.scroll-none::-webkit-scrollbar {
	display: none;
}

.no-whitespace {
	white-space: nowrap;
}

.scroll-overflow-y::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 7px;
}
.scroll-overflow-y::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.5);
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.scroll-overflow-y {
	overflow: -moz-scrollbars-vertical;
	overflow-y: scroll;
}

.overflow-hidden {
	overflow: hidden;
}

.break-word {
	overflow-wrap: break-word;
}

.box-shadow-none {
	box-shadow: none !important;
}

.box-shadow-all {
	@include dropShadowAll();
}

.box-shadow-second-neutral-60 {
	box-shadow: 0 0 12px 0 $firstNeutral60;
}

.box-shadow-first-neutral-50 {
	box-shadow: 0 4px 6px 0 $firstNeutral50;
}

.box-shadow-first-neutral-30 {
	box-shadow: 0 4px 6px 0 $firstNeutral30;
}

.box-shadow-first-neutral-20 {
	box-shadow: 0 4px 6px 0 $firstNeutral20;
}

.box-shadow-first-neutral-30-2 {
	box-shadow: 5px 4px 4px 0 $firstNeutral30;
}

.box-shadow-black-10 {
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

@media screen and (min-width: 768px) {
	.box-shadow-md-none {
		box-shadow: none !important;
	}

	.box-shadow-md-first-neutral-30-2 {
		box-shadow: 5px 4px 4px 0 $firstNeutral30;
	}

	.box-shadow-md-first-neutral-20 {
		box-shadow: 0 4px 6px 0 $firstNeutral20;
	}

	.box-shadow-md-first-neutral-30 {
		box-shadow: 0 4px 6px 0 $firstNeutral30;
	}

	.scroll-md-overflow {
		overflow: scroll;
	}

	.scroll-md-overflow::-webkit-scrollbar {
		display: none;
	}

	.box-shadow-md-all {
		@include dropShadowAll();
	}
}
@media screen and (min-width: 992px) {
	.box-shadow-lg-none {
		box-shadow: none !important;
	}

	.box-shadow-lg-first-neutral-30-2 {
		box-shadow: 5px 4px 4px 0 $firstNeutral30;
	}

	.box-shadow-lg-first-neutral-20 {
		box-shadow: 0 4px 6px 0 $firstNeutral20;
	}

	.box-shadow-lg-first-neutral-30 {
		box-shadow: 0 4px 6px 0 $firstNeutral30;
	}

	.scroll-lg-overflow {
		overflow: scroll;
		overflow-x: hidden;
	}

	.scroll-lg-overflow::-webkit-scrollbar {
		display: none;
	}

	.box-shadow-lg-all {
		@include dropShadowAll();
	}
}
@media screen and (min-width: 1200px) {
	.box-shadow-xl-none {
		box-shadow: none !important;
	}

	.box-shadow-xl-first-neutral-30-2 {
		box-shadow: 5px 4px 4px 0 $firstNeutral30;
	}

	.box-shadow-xl-first-neutral-20 {
		box-shadow: 0 4px 6px 0 $firstNeutral20;
	}

	.box-shadow-xl-first-neutral-30 {
		box-shadow: 0 4px 6px 0 $firstNeutral30;
	}

	.scroll-xl-overflow {
		overflow: scroll;
	}

	.scroll-xl-overflow::-webkit-scrollbar {
		display: none;
	}

	.box-shadow-xl-all {
		@include dropShadowAll();
	}
}
