@media screen and (min-width: 992px) {
	.form-add-user {
		background-color: white;
		padding: 48px;
		border-radius: 10px;
		@include dropShadow();
	}
}
@media screen and (min-width: 1200px) {
	.form-add-user {
		background-color: white;
		padding: 48px;
		border-radius: 10px;
		@include dropShadow();
	}
}
