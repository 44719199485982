* {
	margin: 0;
	padding: 0;
}

@media print {
	html,
	body,
	#root {
		display: none; /* hide whole page */
	}
}

html,
body,
#root {
	font-size: 16px;
	background-color: $thirdNeutral;
	height: 100%;
}

.MuiCircularProgress-root {
	width: 20px !important;
	height: 20px !important;
}

.MuiCircularProgress-colorPrimary {
	color: white !important;
}
