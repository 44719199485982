.pl-form {
  padding-left: 40px;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink {
  flex-shrink: 3;
}

.position-absolute-list {
  position: absolute;
  top: 47px;
}

.position-absolute-warning-form {
  position: absolute;
  top: 0px;
}

.margin-botones {
  margin-bottom: 32px;
}

.margin-guardados {
  margin-bottom: 20px;
}

select {
  -webkit-appearance: menulist-button;
  color: black;
}

select:invalid {
  color: green;
}