.card {
  @include dropShadow();
  border-radius: 5px;
  background-color: $thirdNeutral;
  height: 66px;
  &__image {
    min-width: 50px;
    max-width: 50px;
    // width: 50px;
  }
}

.card-overview {
  @include dropShadow();
  border-radius: 5px;
  background-color: $thirdNeutral;
  &__image {
    min-width: 50px;
    max-width: 50px;
    // width: 50px;
  }
}

.card-podcast {
  @include dropShadow();
  border-radius: 5px;
  background-color: $thirdNeutral;
  max-width: 205px;
  // height: 66px;
  // &__image {
  //   min-width: 50px;
  //   max-width: 50px;
  //   // width: 50px;
  // }
}

.container-image-podcast{
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #c5e4ea;
  border-radius: 15px !important;
}

.subcontainer-image-podcast {
  width: 150px;
  height: 150px;
}

.image-podcast-list {
  // width: 80%;
  // height: 80%;
  // max-height: 100px;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  border-radius: 15px;
}

.container-image-podcast-vertical{
  width: 100px;
  height: 100px;
  border: 1px solid #c5e4ea;
  border-radius: 15px !important;
}

.image-podcast-list-vertical {
  width: 100%;
  height: 100%;
  // width: 100%;
  // height: 100%;
  object-fit: scale-down;
  border-radius: 15px;
}

.image-podcast-list-vertical-episode {
  width: 90%;
  height: 90%;
  max-width: 70px;
  // width: 100%;
  // height: 100%;
  object-fit: scale-down;
  border-radius: 15px;
  border: 1px solid #c5e4ea;
}

.image-podcast-list-preview {
  width: 90%;
  height: 90%;
  max-width: 50px;
  // width: 100%;
  // height: 100%;
  object-fit: scale-down;
  border-radius: 5px;
  border: 1px solid #c5e4ea;
}

.image-border {
  border-radius: 15px;
  border: 1px solid #c5e4ea;
}

.program-cover{
  width: 100%;
  height: 100%;
  max-width: 100px;
  object-fit: scale-down;
  border-radius: 15px;
  border: 1px solid #c5e4ea;
  background-color: white;
}

.pdf-img{
  padding-top: 1px;
}
.share-img{
  padding-bottom: 2px;
}
.img-details{
  object-fit: contain;
  width: -webkit-fill-available;

}

.profile-ancor:hover{
  text-decoration: underline $firstPrimary70;



}
.align-filters{
  justify-content: space-between;
  margin-top: 16px !important;
}
.width-card-news{
  width: 264px;
}
.image-news{
  aspect-ratio: 1 / 1;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.btn-in{
  border: none;
    background: none;
}


// Estilos del profile component

.poper-profile{
  background-color: black;
  pointer-events: none;
}


//card de blog auhtors 
.card-author{
  height: 50px;
  width: 200px;
  border-radius: 10px;
}

@media screen and (min-width: 768px) {
  .card--large {
    background-color: white;
    padding: 0px 24px 48px 24px;
    border-radius: 10px;
    @include dropShadow();
    margin: auto;
    max-width: 352px;
  }
  .image-news{
    width: 249px !important;

  }

}
@media screen and (min-width: 1200px) {

.align-filters{
  float: right;
  margin-top: 0px !important;

}

}

.icon-user-blog{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

@media screen and (min-width: 992px){
  .program-cover{
    width: 100%;
    height: 100%;
    max-width: 200px;
    object-fit: scale-down;
    border-radius: 15px;
    border: 1px solid #c5e4ea;
    background-color: white;
  }

  .image-podcast-list-vertical-episode {
    width: 90%;
    height: 90%;
    max-width: 110px;
    // width: 100%;
    // height: 100%;
    object-fit: scale-down;
    border-radius: 15px;
    border: 1px solid #c5e4ea;
  }

  .container-image-podcast-vertical{
    width: 124px;
    height: 124px;
    border: 1px solid #c5e4ea;
    border-radius: 15px !important;
  }
}
