.b-none {
	border: none !important;
}

.border-white {
	border: 2px solid $thirdNeutral;
}

.border-first-primary-50 {
	border: 1px solid $firstPrimary50;
}
.border-first-neutral-60 {
	border: 2px solid $firstNeutral60;
}
.border-first-neutral-30 {
	border: 2px solid $firstNeutral30 !important;
}
.border-second-primary-10 {
	border: 2px solid $secondPrimary10 !important;
}
.border-first-neutral-50 {
	border: 2px solid $firstNeutral50;
}

.border-bottom-second-primary-50 {
	border-bottom: 3px solid $secondPrimary50;
}

.border-bottom-second-Neutral-100 {
	border-bottom: 1px solid $secondNeutral100;
}

.border-top-first-Neutral-50 {
	border-top: 1px solid $firstNeutral50;
}
.border-first-primary-10 {
	border: 2px solid $firstPrimary10;
}

.border-ocean-30 {
	border: 1px solid $Ocean30 !important;
}

.border-first-primary-60 {
	border: 1px solid rgba(53, 84, 113, 0.2);
}

.br-0 {
	border-radius: 0;
}

.br-1 {
	border-radius: 5px;
}

.br-2 {
	border-radius: 10px !important;
}

.br-3 {
	border-radius: 15px;
}

.br-4 {
	border-radius: 20px;
}

.br-5 {
	border-radius: 25px;
}
.br-6 {
	border-radius: 110px !important;
}

.br-7 {
	border-radius: 50px;
}

.br-t-0 {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.br-t-1 {
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.br-t-2 {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.br-t-3 {
	border-top-left-radius: 15px !important;
	border-top-right-radius: 15px !important;
}

.br-tr-2 {
	border-top-right-radius: 10px;
}

.br-tl-2 {
	border-top-left-radius: 10px;
}

.br-br-2 {
	border-bottom-right-radius: 10px;
}

.br-bl-2 {
	border-bottom-left-radius: 10px;
}

.br-b-1 {
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.br-b-2 {
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.br-b-3 {
	border-bottom-left-radius: 15px !important;
	border-bottom-right-radius: 15px !important;
}

@media screen and (min-width: 768px) {
	.br-md-0 {
		border-radius: 0;
	}

	.br-md-1 {
		border-radius: 5px;
	}

	.br-md-2 {
		border-radius: 10px;
	}

	.br-md-3 {
		border-radius: 15px;
	}

	.br-md-4 {
		border-radius: 20px;
	}

	.br-md-5 {
		border-radius: 25px;
	}

	.br-t-md-0 {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	.br-tr-md-2 {
		border-top-right-radius: 10px;
	}

	.br-tl-md-2 {
		border-top-left-radius: 10px;
	}

	.b-md-none {
		border: none !important;
	}
}
@media screen and (min-width: 992px) {
	.br-lg-0 {
		border-radius: 0;
	}

	.br-lg-1 {
		border-radius: 5px;
	}

	.br-lg-2 {
		border-radius: 10px;
	}

	.br-lg-3 {
		border-radius: 15px;
	}

	.br-lg-4 {
		border-radius: 20px;
	}

	.br-lg-5 {
		border-radius: 25px;
	}

	.br-t-lg-0 {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	.br-tr-lg-2 {
		border-top-right-radius: 10px;
	}

	.br-tl-lg-2 {
		border-top-left-radius: 10px;
	}

	.b-lg-none {
		border: none !important;
	}
}
@media screen and (min-width: 1200px) {
	.br-xl-0 {
		border-radius: 0;
	}

	.br-xl-1 {
		border-radius: 5px;
	}

	.br-xl-2 {
		border-radius: 10px;
	}

	.br-xl-3 {
		border-radius: 15px;
	}

	.br-xl-4 {
		border-radius: 20px;
	}

	.br-xl-5 {
		border-radius: 25px;
	}

	.br-t-xl-0 {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	.br-tr-xl-2 {
		border-top-right-radius: 10px;
	}

	.br-tl-xl-2 {
		border-top-left-radius: 10px;
	}

	.b-xl-none {
		border: none !important;
	}
}
