.ContainerTag {
    display: flex;
    overflow: scroll;
    width: 100%;
    max-width: 100%;
    padding-left: 14px;
    border: 1px grey solid;
    border-radius: 5px;
    color: black
    
  }
  
  .ContainerTag input {
    width: 100%;
    border: none;
    outline: none;
    padding-top: 12px;
    padding-bottom: 12px;

  }
  .ContainerTag input:focus {
    width: 100%;
    border: none;
    
  }
  
  .tag {
    display: flex;
    align-items: center;
    margin: 7px 0;
    margin-right: 10px;
    padding: 0 10px;
    padding-right: 5px;
    border: 1px solid $Ocean30;
    border-radius: 20px;
    background-color: white;
    white-space: nowrap;
    font-family: $robotoRegular;
    color: $firstPrimary70;
  }
  
  .tag button {
    display: flex;
    padding: 6px;
    border: none;
    background-color: unset;
    cursor: pointer;
    font-family: $robotoRegular;
    color: $firstPrimary70;
  }