.question-img-card{
    height: 115px;
    padding-top: 8px;
    background-color: $firstPrimary10;
    padding-bottom: 12px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.bg-blue-card{
    background-color: $firstPrimary10;
}

.bck-color-white{
    background-color: $thirdNeutral;
}