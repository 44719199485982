.input-profile{
    font-size: 14px !important;
    border: none !important;
    border-bottom: 1px solid #355471 !important;
    border-radius: 0 !important;
    padding-left: 0 !important;
    padding-top: 0 !important;
    width: 100% !important;
}

.input-profile-lg{
    color: $firstNeutral80 !important;
    font-family: $robotoMedium !important;
}

.input-profile::placeholder{
    color: $firstNeutral80 !important;
    font-family: $robotoMedium !important;
}

.input-profile-after{
    color: $secondNeutral60 !important;
}

.select-list{
    font-size: 14px !important;
}

@media screen and (min-width: 768px) {
    .input-profile{
        font-size: 18px !important;
    }

    .select-list{
        font-size: 18px !important;
    }
}