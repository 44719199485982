.logo-header-admin {
  width: 146px;
}

.menu-icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.header-admin {
  max-width: 1200px;
  margin: auto;
}

@media screen and (min-width: 768px) {
  .logo-header-admin {
    width: 244px;
  }
}

@media screen and (min-width: 1200px) {
  .logo-header-admin {
    width: 146px;
  }
}
