.cursor-pointer {
	cursor: pointer;
}

.disabled {
	opacity: 0.5;
	pointer-events: none !important;
}

.activated {
	opacity: 1;
	pointer-events: all;
}

.cursor-default {
	cursor: default !important;
}

.cursor-no-drop {
	cursor: no-drop;
}

.card-question:hover {
	background-color: #fbfbfb;
	border: 0.2px solid $firstPrimary10;
}

@media screen and (min-width: 768px) {
	.disabled-md {
		opacity: 0.5;
		pointer-events: none;
	}
	.activated-md {
		opacity: 1;
		pointer-events: all;
	}
}
@media screen and (min-width: 992px) {
	.disabled-lg {
		opacity: 0.5;
		pointer-events: none;
	}
	.activated-lg {
		opacity: 1;
		pointer-events: all;
	}
}
@media screen and (min-width: 1200px) {
	.disabled-xl {
		opacity: 0.5;
		pointer-events: none;
	}
	.activated-xl {
		opacity: 1;
		pointer-events: all;
	}
}
