.h-100Full {
	height: 100%;
}

.h-a {
	height: auto !important;
}

.item1 {
	height: 200px;
	width: 100%;
	margin-bottom: 12px;
	object-fit: cover;
}

.itemN {
	height: 90px;
	object-fit: cover;
	width: 100%;
}

.hr-h-1 {
	height: 1px;
}

.h-100 {
	height: 100px;
}
.h-120 {
	height: 120px !important;
}

.h-128 {
	height: 128px !important;
}
.h-144 {
	height: 144px !important;
}

.h-20 {
	height: 40%;
}

.h-100vh-search-company {
	height: calc(100vh - 247px);
}

.h-100vh-100px {
	height: calc(100vh - 100px);
}

.h-500px {
	height: 500px;
}
.h-519 {
	height: 519px;
}
.h-40 {
	height: 40px;
}
.h-min {
	min-height: 50px;
}
.h-min-46 {
	min-height: 46px;
}

.h-min-grid {
	max-height: 200px;
}
.h-min-500 {
	min-height: 500px;
}
.h-min-grid-card {
	max-height: 200px;
}

.h-max-200 {
	max-height: 200px;
}

.h-max-440 {
	max-height: 440px;
}

.h-max-45vh {
	max-height: 45vh;
}

.h-600px {
	height: 600px;
}

@media screen and (min-width: 768px) {
	.h-100vh-search-company {
		height: calc(100vh - 285px);
	}
	.h-min-grid {
		max-height: 500px !important;
	}
	.h-min-grid-card {
		max-height: 500px !important;
	}
	.item1 {
		height: 500px;
	}
	.itemN {
		height: 232px;
		margin-bottom: 10px;
	}
}
@media screen and (min-width: 992px) {
	.h-100vh {
		height: calc(100vh);
	}

	.h-100vh-header {
		height: calc(100vh - 88px);
	}
	.h-100vh-search-company {
		height: 70vh;
	}
	.h-100vh-list-company {
		height: calc(100vh - 112px);
	}
	.h-100vh-list-question {
		height: calc(100vh - 220px);
	}
	.h-max-modal-img {
		height: 100px !important;
	}
	.h-min-grid {
		max-height: 400px !important;
	}
	.h-min-grid-card {
		max-height: 400px !important;
	}

	.item1 {
		height: 400px;
	}
	.itemN {
		height: 182px;
		margin-bottom: 5px;
	}
	.container-cidUser {
		max-height: 300px;
	}
}

@media screen and (min-width: 1200px) {
	.h-100vh-header {
		height: 103vh;
	}

	.h-100vh-search-company {
		height: 75vh;
	}
	.h-100vh-list-company {
		height: calc(100vh - 88px);
	}
	.h-min-grid {
		max-height: 150px !important;
	}
	.h-min-grid-card {
		max-height: 500px !important;
	}
	.item1 {
		height: 150px;
	}
	.itemN {
		height: 60px;
		margin-bottom: 5px;

		.container-cidUser {
			max-height: 200px !important;
		}
	}
}
