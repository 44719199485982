.menu-cid {
    &_logo {
        width: 120px;
  }
}

.header-icon {
  width: 24px;
}
.header-mr{
    margin-right: 16px;
}

@media screen and (min-width: 768px) {
    .menu-cid {
        &_logo {
            width: 150px;
        }
    }

    .header-icon {
        width: 40px;
    }
   
}
@media screen and (min-width: 992px) {
    .hmr{
        margin-right: 8px;
    }
}
@media screen and (max-width: 1000px) {
    .header-mt-3{
        margin-top: 16px !important;
    }
    .header-mr{
        margin-right: 8px;
    }
}
