@import '../variables';

.m-auto {
	margin: auto;
}

.mx-auto {
	margin-left: auto !important;
	margin-right: auto !important;
}
.m-20vh {
	margin-top: 20vh;
}
.m-0 {
	margin: 0;
}

.m-1 {
	margin: $spacing * 1;
}

.m-2 {
	margin: $spacing * 2;
}

.m-3 {
	margin: $spacing * 4;
}

.m-4 {
	margin: $spacing * 6;
}

.m-5 {
	margin: $spacing * 12;
}

.ml-auto {
	margin-left: auto;
}

.ml-0 {
	margin-left: 0 !important;
}

.ml-1 {
	margin-left: $spacing * 1;
}

.ml-2 {
	margin-left: $spacing * 2;
}

.ml-3 {
	margin-left: $spacing * 4;
}

.ml-4 {
	margin-left: $spacing * 6;
}

.ml-5 {
	margin-left: $spacing * 12;
}
.ml-7 {
	margin-left: $spacing * 14;
}
.ml-8 {
	margin-left: $spacing * 16;
}
.ml-9 {
	margin-left: $spacing * 8;
}
.ml-10 {
	margin-left: $spacing * 3;
}
.ml-11 {
	margin-left: $spacing * 33;
}
.ml-12 {
	margin-left: $spacing * 19;
}
.ml-13 {
	margin-left: $spacing * 7;
}
.ml-14 {
	margin-left: $spacing * 11;
}
.mb-0 {
	margin-bottom: 0;
}

.mb-0-imp {
	margin-bottom: 0 !important;
}

.mb-1 {
	margin-bottom: $spacing * 1;
}

.mb-2 {
	margin-bottom: $spacing * 2;
}

.mb-3 {
	margin-bottom: $spacing * 4;
}

.mb-4 {
	margin-bottom: $spacing * 6;
}

.mb-5 {
	margin-bottom: $spacing * 12;
}

.mb-6 {
	margin-bottom: $spacing * 8;
}

.mb-7 {
	margin-bottom: $spacing * 10;
}
.mb-8 {
	margin-bottom: $spacing * 3;
}
.mb-9 {
	margin-bottom: $spacing * 5;
}
.mb-10 {
	margin-bottom: $spacing * 7;
}

.mb-20 {
	margin-bottom: $spacing * 50;
}

.mr-0 {
	margin-right: 0 !important;
}

.mr-1 {
	margin-right: $spacing * 1;
}

.mr-2 {
	margin-right: $spacing * 2;
}

.mr-3 {
	margin-right: $spacing * 4;
}

.mr-4 {
	margin-right: $spacing * 6;
}

.mr-5 {
	margin-right: $spacing * 12;
}

.mr-6 {
	margin-right: $spacing * 16;
}
.mr-7 {
	margin-right: $spacing * 3;
}

.mr-8 {
	margin-right: $spacing * 13;
}

.mr-9 {
	margin-right: $spacing * 10;
}

.mr-10 {
	margin-right: $spacing * 7;
}

.mt-0 {
	margin-top: 0 !important;
}

.mt-1 {
	margin-top: $spacing * 1;
}

.mt-2 {
	margin-top: $spacing * 2 !important;
}

.mt-3 {
	margin-top: $spacing * 4 !important;
}

.mt-4 {
	margin-top: $spacing * 6;
}

.mt-5 {
	margin-top: $spacing * 12 !important;
}

.mt-6 {
	margin-top: $spacing * 8 !important;
}
.mt-7 {
	margin-top: $spacing * 10;
}
.mt-8 {
	margin-top: $spacing * 3;
}
.mt-9 {
	margin-top: $spacing * 5;
}
.mt-10 {
	margin-top: $spacing * 11;
}
.mt-11 {
	margin-top: $spacing * 7;
}

.mt-12 {
	margin-top: $spacing * 18;
}

.mt-13 {
	margin-top: $spacing * 9;
}

.mt-auto {
	margin-top: auto;
}
.p-0 {
	padding: 0;
}

.p-1 {
	padding: $spacing * 1;
}

.p-2 {
	padding: $spacing * 2;
}

.p-3 {
	padding: $spacing * 4;
}

.p-4 {
	padding: $spacing * 6;
}

.p-5 {
	padding: $spacing * 12;
}

.pt-0 {
	padding-top: 0;
}

.pt-1 {
	padding-top: $spacing * 1;
}

.pt-2 {
	padding-top: $spacing * 2;
}

.pt-3 {
	padding-top: $spacing * 4;
}

.pt-4 {
	padding-top: $spacing * 6;
}

.pt-5 {
	padding-top: $spacing * 12;
}

.pt-6 {
	padding-top: $spacing * 8;
}

.pl-0 {
	padding-left: 0;
}

.pl-1 {
	padding-left: $spacing * 1;
}

.pl-2 {
	padding-left: $spacing * 2;
}

.pl-3 {
	padding-left: $spacing * 4;
}

.pl-4 {
	padding-left: $spacing * 6;

}

.pl-5 {
	padding-left: $spacing * 12 !important;
}

.pl-7 {
	padding-left: $spacing * 8;
}

.pb-0 {
	padding-bottom: 0;
}

.pb-1 {
	padding-bottom: $spacing * 1;
}

.pb-2 {
	padding-bottom: $spacing * 2;
}

.pb-3 {
	padding-bottom: $spacing * 4;
}

.pb-4 {
	padding-bottom: $spacing * 6;
}

.pb-5 {
	padding-bottom: $spacing * 12;
}

.pb-6 {
	padding-bottom: $spacing * 8;
}

.pb-7 {
	padding-bottom: $spacing * 7;
}

.pb-8 {
	padding-bottom: $spacing * 5;
}

.pr-0 {
	padding-right: 0;
}

.pr-1 {
	padding-right: $spacing * 1;
}

.pr-2 {
	padding-right: $spacing * 2;
}

.pr-3 {
	padding-right: $spacing * 4;
}

.pr-4 {
	padding-right: $spacing * 6;
}

.pr-5 {
	padding-right: $spacing * 12;
}

.pr-6 {
	padding-right: $spacing * 3;
}

.my-0 {
	margin-bottom: 0;
	margin-top: 0;
}

.my-1 {
	margin-bottom: $spacing * 1;
	margin-top: $spacing * 1;
}

.my-2 {
	margin-bottom: $spacing * 2;
	margin-top: $spacing * 2;
}

.my-3 {
	margin-bottom: $spacing * 4;
	margin-top: $spacing * 4;
}

.my-4 {
	margin-bottom: $spacing * 6;
	margin-top: $spacing * 6;
}

.my-5 {
	margin-bottom: $spacing * 12;
	margin-top: $spacing * 12;
}

.mx-0 {
	margin-left: 0;
	margin-right: 0;
}

.mx-1 {
	margin-left: $spacing * 1;
	margin-right: $spacing * 1;
}

.mx-2 {
	margin-left: $spacing * 2;
	margin-right: $spacing * 2;
}

.mx-3 {
	margin-left: $spacing * 4;
	margin-right: $spacing * 4;
}

.mx-4 {
	margin-left: $spacing * 6;
	margin-right: $spacing * 6;
}

.mx-5 {
	margin-left: $spacing * 12;
	margin-right: $spacing * 12;
}

.mx-6 {
	margin-left: $spacing * 7;
	margin-right: $spacing * 7;
}

.mx-7 {
	margin-left: $spacing * 15.5;
	margin-right: $spacing * 15.5;
}

.py-0 {
	padding-bottom: 0;
	padding-top: 0;
}

.py-1 {
	padding-bottom: $spacing * 1;
	padding-top: $spacing * 1;
}

.py-2 {
	padding-bottom: $spacing * 2;
	padding-top: $spacing * 2;
}

.py-2-5 {
	padding-bottom: $spacing * 1.5;
	padding-top: $spacing * 1.5;
}

.py-3 {
	padding-bottom: $spacing * 4;
	padding-top: $spacing * 4;
}

.py-4 {
	padding-bottom: $spacing * 6;
	padding-top: $spacing * 6;
}

.py-5 {
	padding-bottom: $spacing * 12;
	padding-top: $spacing * 12;
}

.py-6 {
	padding-top: $spacing * 8;
	padding-bottom: $spacing * 8;
}

.py-7 {
	padding-top: $spacing * 5;
	padding-bottom: $spacing * 5;
}

.py-8 {
	padding-top: $spacing * 10;
	padding-bottom: $spacing * 10;
}

.px-0 {
	padding-left: 0;
	padding-right: 0;
}

.px-1 {
	padding-left: $spacing * 1;
	padding-right: $spacing * 1;
}

.px-2 {
	padding-left: $spacing * 2;
	padding-right: $spacing * 2;
}

.px-3 {
	padding-left: $spacing * 4;
	padding-right: $spacing * 4;
}

.px-4 {
	padding-left: $spacing * 6;
	padding-right: $spacing * 6;
}

.px-5 {
	padding-left: $spacing * 12;
	padding-right: $spacing * 12;
}
.px-6 {
	padding-left: $spacing * 16;
	padding-right: $spacing * 16;
}
.px-7 {
	padding-left: $spacing * 7;
	padding-right: $spacing * 7;
}

.px-8 {
	padding-left: $spacing * 10;
	padding-right: $spacing * 10;
}

@media screen and (min-width: 768px) {
	.m-md-auto {
		margin: auto;
	}

	.mx-md-auto {
		margin: 0 auto;
	}

	.m-md-0 {
		margin: 0;
	}

	.m-md-1 {
		margin: $spacing * 1;
	}

	.m-md-2 {
		margin: $spacing * 2;
	}

	.m-md-3 {
		margin: $spacing * 4;
	}

	.m-md-4 {
		margin: $spacing * 6;
	}

	.m-md-5 {
		margin: $spacing * 12;
	}

	.ml-md-0 {
		margin-left: 0;
	}

	.ml-md-1 {
		margin-left: $spacing * 1;
	}

	.ml-md-2 {
		margin-left: $spacing * 2;
	}

	.ml-md-3 {
		margin-left: $spacing * 4;
	}

	.ml-md-4 {
		margin-left: $spacing * 6 !important;
	}

	.ml-md-5 {
		margin-left: $spacing * 12;
	}

	.ml-md-6 {
		margin-left: $spacing * 24;
	}

	.mb-md-0 {
		margin-bottom: 0;
	}

	.mb-md-1 {
		margin-bottom: $spacing * 1;
	}

	.mb-md-2 {
		margin-bottom: $spacing * 2;
	}

	.mb-md-3 {
		margin-bottom: $spacing * 4;
	}

	.mb-md-4 {
		margin-bottom: $spacing * 6;
	}
	.mb-md-7 {
		margin-bottom: $spacing * 8;
	}

	.mb-md-5 {
		margin-bottom: $spacing * 12;
	}

	.mr-md-0 {
		margin-right: 0;
	}

	.mr-md-1 {
		margin-right: $spacing * 1;
	}

	.mr-md-2 {
		margin-right: $spacing * 2;
	}

	.mr-md-3 {
		margin-right: $spacing * 4;
	}

	.mr-md-4 {
		margin-right: $spacing * 6;
	}

	.mr-md-5 {
		margin-right: $spacing * 12;
	}

	.mt-md-0 {
		margin-top: 0;
	}

	.mt-md-1 {
		margin-top: $spacing * 1 !important;
	}

	.mt-md-2 {
		margin-top: $spacing * 2;
	}

	.mt-md-3 {
		margin-top: $spacing * 4 !important;
	}

	.mt-md-4 {
		margin-top: $spacing * 6 !important;
	}

	.mt-md-5 {
		margin-top: $spacing * 12;
	}
	.mt-md-7 {
		margin-top: $spacing * 8;
	}

	.p-md-0 {
		padding: 0;
	}

	.p-md-1 {
		padding: $spacing * 1;
	}

	.p-md-2 {
		padding: $spacing * 2;
	}

	.p-md-3 {
		padding: $spacing * 4;
	}

	.p-md-4 {
		padding: $spacing * 6;
	}

	.p-md-5 {
		padding: $spacing * 12;
	}

	.pt-md-0 {
		padding-top: 0;
	}

	.pt-md-1 {
		padding-top: $spacing * 1;
	}

	.pt-md-2 {
		padding-top: $spacing * 2;
	}

	.pt-md-3 {
		padding-top: $spacing * 4;
	}

	.pt-md-4 {
		padding-top: $spacing * 6;
	}

	.pt-md-5 {
		padding-top: $spacing * 12;
	}

	.pl-md-0 {
		padding-left: 0;
	}

	.pl-md-1 {
		padding-left: $spacing * 1;
	}

	.pl-md-2 {
		padding-left: $spacing * 2;
	}

	.pl-md-3 {
		padding-left: $spacing * 4;
	}

	.pl-md-4 {
		padding-left: $spacing * 6;
	}

	.pl-md-5 {
		padding-left: $spacing * 12;
	}

	.pb-md-0 {
		padding-bottom: 0;
	}

	.pb-md-1 {
		padding-bottom: $spacing * 1;
	}

	.pb-md-2 {
		padding-bottom: $spacing * 2;
	}

	.pb-md-3 {
		padding-bottom: $spacing * 4;
	}

	.pb-md-4 {
		padding-bottom: $spacing * 6;
	}

	.pb-md-5 {
		padding-bottom: $spacing * 12;
	}

	.pr-md-0 {
		padding-right: 0;
	}

	.pr-md-1 {
		padding-right: $spacing * 1;
	}

	.pr-md-2 {
		padding-right: $spacing * 2;
	}

	.pr-md-3 {
		padding-right: $spacing * 4;
	}

	.pr-md-4 {
		padding-right: $spacing * 6;
	}

	.pr-md-5 {
		padding-right: $spacing * 12;
	}

	.my-md-0 {
		margin-bottom: 0;
		margin-top: 0;
	}

	.my-md-1 {
		margin-bottom: $spacing * 1;
		margin-top: $spacing * 1;
	}

	.my-md-2 {
		margin-bottom: $spacing * 2;
		margin-top: $spacing * 2;
	}

	.my-md-3 {
		margin-bottom: $spacing * 4;
		margin-top: $spacing * 4;
	}

	.my-md-4 {
		margin-bottom: $spacing * 6;
		margin-top: $spacing * 6;
	}

	.my-md-5 {
		margin-bottom: $spacing * 12;
		margin-top: $spacing * 12;
	}

	.mx-md-0 {
		margin-left: 0;
		margin-right: 0;
	}

	.mx-md-1 {
		margin-left: $spacing * 1;
		margin-right: $spacing * 1;
	}

	.mx-md-2 {
		margin-left: $spacing * 2;
		margin-right: $spacing * 2;
	}

	.mx-md-3 {
		margin-left: $spacing * 4;
		margin-right: $spacing * 4;
	}

	.mx-md-4 {
		margin-left: $spacing * 6;
		margin-right: $spacing * 6;
	}

	.mx-md-5 {
		margin-left: $spacing * 12;
		margin-right: $spacing * 12;
	}

	.py-md-0 {
		padding-bottom: 0;
		padding-top: 0;
	}

	.py-md-1 {
		padding-bottom: $spacing * 1;
		padding-top: $spacing * 1;
	}

	.py-md-2 {
		padding-bottom: $spacing * 2;
		padding-top: $spacing * 2;
	}

	.py-md-3 {
		padding-bottom: $spacing * 4;
		padding-top: $spacing * 4;
	}

	.py-md-4 {
		padding-bottom: $spacing * 6;
		padding-top: $spacing * 6;
	}

	.py-md-5 {
		padding-bottom: $spacing * 12;
		padding-top: $spacing * 12;
	}

	.px-md-0 {
		padding-left: 0;
		padding-right: 0;
	}

	.px-md-1 {
		padding-left: $spacing * 1;
		padding-right: $spacing * 1;
	}

	.px-md-2 {
		padding-left: $spacing * 2;
		padding-right: $spacing * 2;
	}

	.px-md-3 {
		padding-left: $spacing * 4;
		padding-right: $spacing * 4;
	}

	.px-md-4 {
		padding-left: $spacing * 6;
		padding-right: $spacing * 6;
	}

	.px-md-5 {
		padding-left: $spacing * 12;
		padding-right: $spacing * 12;
	}
}

@media screen and (min-width: 992px) {
	.m-lg-auto {
		margin: auto;
	}

	.mx-lg-auto {
		margin: 0 auto;
	}

	.m-lg-0 {
		margin: 0;
	}

	.m-lg-1 {
		margin: $spacing * 1 !important;
	}

	.m-lg-2 {
		margin: $spacing * 2 !important;
	}

	.m-lg-3 {
		margin: $spacing * 4 !important;
	}

	.m-lg-4 {
		margin: $spacing * 6;
	}

	.m-lg-5 {
		margin: $spacing * 12;
	}

	.ml-lg-0 {
		margin-left: 0;
	}

	.ml-lg-1 {
		margin-left: $spacing * 1;
	}

	.ml-lg-2 {
		margin-left: $spacing * 2;
	}

	.ml-lg-3 {
		margin-left: $spacing * 4 !important;
	}

	.ml-lg-4 {
		margin-left: $spacing * 6;
	}

	.ml-lg-5 {
		margin-left: $spacing * 12;
	}

	.mb-lg-0 {
		margin-bottom: 0;
	}

	.mb-lg-1 {
		margin-bottom: $spacing * 1;
	}

	.mb-lg-2 {
		margin-bottom: $spacing * 2 !important;
	}

	.mb-lg-3 {
		margin-bottom: $spacing * 4;
	}

	.mb-lg-4 {
		margin-bottom: $spacing * 6;
	}

	.mb-lg-5 {
		margin-bottom: $spacing * 12;
	}

	.mr-lg-0 {
		margin-right: 0;
	}

	.mr-lg-1 {
		margin-right: $spacing * 1;
	}

	.mr-lg-2 {
		margin-right: $spacing * 2;
	}

	.mr-lg-3 {
		margin-right: $spacing * 4;
	}

	.mr-lg-4 {
		margin-right: $spacing * 6;
	}

	.mr-lg-5 {
		margin-right: $spacing * 12;
	}

	.mr-lg-6 {
		margin-right: $spacing * 20;
	}

	.mt-lg-0 {
		margin-top: 0;
	}

	.mt-lg-1 {
		margin-top: $spacing * 1 !important;
	}

	.mt-lg-2 {
		margin-top: $spacing * 2;
	}

	.mt-lg-3 {
		margin-top: $spacing * 4;
	}

	.mt-lg-4 {
		margin-top: $spacing * 6;
	}

	.mt-lg-5 {
		margin-top: $spacing * 12;
	}

	.p-lg-0 {
		padding: 0;
	}

	.p-lg-1 {
		padding: $spacing * 1;
	}

	.p-lg-2 {
		padding: $spacing * 2;
	}

	.p-lg-3 {
		padding: $spacing * 4;
	}

	.p-lg-4 {
		padding: $spacing * 6;
	}

	.p-lg-5 {
		padding: $spacing * 12;
	}

	.pt-lg-0 {
		padding-top: 0;
	}

	.pt-lg-1 {
		padding-top: $spacing * 1;
	}

	.pt-lg-2 {
		padding-top: $spacing * 2;
	}

	.pt-lg-3 {
		padding-top: $spacing * 4;
	}

	.pt-lg-4 {
		padding-top: $spacing * 6;
	}

	.pt-lg-5 {
		padding-top: $spacing * 12;
	}

	.pl-lg-0 {
		padding-left: 0;
	}

	.pl-lg-1 {
		padding-left: $spacing * 1;
	}

	.pl-lg-2 {
		padding-left: $spacing * 2;
	}

	.pl-lg-3 {
		padding-left: $spacing * 4;
	}

	.pl-lg-4 {
		padding-left: $spacing * 6;
	}
	.pl-lg-6 {
		padding-left: $spacing * 11;
	}

	.pl-lg-5 {
		padding-left: $spacing * 12;
	}
	.pl-lg-6 {
		padding-left: $spacing * 14;
	}

	.pb-lg-0 {
		padding-bottom: 0;
	}

	.pb-lg-1 {
		padding-bottom: $spacing * 1;
	}

	.pb-lg-2 {
		padding-bottom: $spacing * 2;
	}

	.pb-lg-3 {
		padding-bottom: $spacing * 4;
	}

	.pb-lg-4 {
		padding-bottom: $spacing * 6;
	}

	.pb-lg-5 {
		padding-bottom: $spacing * 12;
	}

	.pr-lg-0 {
		padding-right: 0;
	}

	.pr-lg-1 {
		padding-right: $spacing * 1;
	}

	.pr-lg-2 {
		padding-right: $spacing * 2;
	}

	.pr-lg-3 {
		padding-right: $spacing * 4;
	}

	.pr-lg-4 {
		padding-right: $spacing * 6;
	}

	.pr-lg-5 {
		padding-right: $spacing * 12;
	}

	.my-lg-0 {
		margin-bottom: 0;
		margin-top: 0;
	}

	.my-lg-1 {
		margin-bottom: $spacing * 1;
		margin-top: $spacing * 1;
	}

	.my-lg-2 {
		margin-bottom: $spacing * 2;
		margin-top: $spacing * 2;
	}

	.my-lg-3 {
		margin-bottom: $spacing * 4;
		margin-top: $spacing * 4;
	}

	.my-lg-4 {
		margin-bottom: $spacing * 6;
		margin-top: $spacing * 6;
	}

	.my-lg-5 {
		margin-bottom: $spacing * 12;
		margin-top: $spacing * 12;
	}

	.mx-lg-0 {
		margin-left: 0;
		margin-right: 0;
	}

	.mx-lg-1 {
		margin-left: $spacing * 1;
		margin-right: $spacing * 1;
	}

	.mx-lg-2 {
		margin-left: $spacing * 2;
		margin-right: $spacing * 2;
	}

	.mx-lg-3 {
		margin-left: $spacing * 4;
		margin-right: $spacing * 4;
	}

	.mx-lg-4 {
		margin-left: $spacing * 6;
		margin-right: $spacing * 6;
	}

	.mx-lg-5 {
		margin-left: $spacing * 12;
		margin-right: $spacing * 12;
	}

	.py-lg-0 {
		padding-bottom: 0;
		padding-top: 0;
	}

	.py-lg-1 {
		padding-bottom: $spacing * 1;
		padding-top: $spacing * 1;
	}

	.py-lg-2 {
		padding-bottom: $spacing * 2;
		padding-top: $spacing * 2;
	}

	.py-lg-3 {
		padding-bottom: $spacing * 4;
		padding-top: $spacing * 4;
	}

	.py-lg-4 {
		padding-bottom: $spacing * 6;
		padding-top: $spacing * 6;
	}

	.py-lg-5 {
		padding-bottom: $spacing * 12;
		padding-top: $spacing * 12;
	}

	.px-lg-0 {
		padding-left: 0;
		padding-right: 0;
	}

	.px-lg-1 {
		padding-left: $spacing * 1;
		padding-right: $spacing * 1;
	}

	.px-lg-2 {
		padding-left: $spacing * 2;
		padding-right: $spacing * 2;
	}

	.px-lg-3 {
		padding-left: $spacing * 4;
		padding-right: $spacing * 4;
	}

	.px-lg-4 {
		padding-left: $spacing * 6;
		padding-right: $spacing * 6;
	}

	.px-lg-5 {
		padding-left: $spacing * 12;
		padding-right: $spacing * 12;
	}
}

@media screen and (min-width: 1100px) {
	.m-xl-auto {
		margin: auto;
	}

	.mx-xl-auto {
		margin: 0 auto;
	}

	.m-xl-0 {
		margin: 0;
	}

	.m-xl-1 {
		margin: $spacing * 1;
	}

	.m-xl-2 {
		margin: $spacing * 2;
	}

	.m-xl-3 {
		margin: $spacing * 4;
	}

	.m-xl-4 {
		margin: $spacing * 6;
	}

	.m-xl-5 {
		margin: $spacing * 12;
	}

	.ml-xl-0 {
		margin-left: 0;
	}

	.ml-xl-1 {
		margin-left: $spacing * 1;
	}

	.ml-xl-2 {
		margin-left: $spacing * 2;
	}

	.ml-xl-3 {
		margin-left: $spacing * 4 !important;
	}

	.ml-xl-4 {
		margin-left: $spacing * 6;
	}

	.ml-xl-5 {
		margin-left: $spacing * 12;
	}

	.mb-xl-0 {
		margin-bottom: 0;
	}

	.mb-xl-1 {
		margin-bottom: $spacing * 1;
	}

	.mb-xl-2 {
		margin-bottom: $spacing * 2;
	}

	.mb-xl-3 {
		margin-bottom: $spacing * 4;
	}

	.mb-xl-4 {
		margin-bottom: $spacing * 6;
	}

	.mb-xl-5 {
		margin-bottom: $spacing * 12;
	}

	.mr-xl-0 {
		margin-right: 0;
	}

	.mr-xl-1 {
		margin-right: $spacing * 1;
	}

	.mr-xl-2 {
		margin-right: $spacing * 2;
	}

	.mr-xl-3 {
		margin-right: $spacing * 4;
	}

	.mr-xl-4 {
		margin-right: $spacing * 6;
	}

	.mr-xl-5 {
		margin-right: $spacing * 12;
	}

	.mt-xl-0 {
		margin-top: 0;
	}

	.mt-xl-1 {
		margin-top: $spacing * 1;
	}

	.mt-xl-2 {
		margin-top: $spacing * 2;
	}

	.mt-xl-3 {
		margin-top: $spacing * 4;
	}

	.mt-xl-4 {
		margin-top: $spacing * 6;
	}

	.mt-xl-5 {
		margin-top: $spacing * 12;
	}

	.p-xl-0 {
		padding: 0;
	}

	.p-xl-1 {
		padding: $spacing * 1;
	}

	.p-xl-2 {
		padding: $spacing * 2;
	}

	.p-xl-3 {
		padding: $spacing * 4;
	}

	.p-xl-4 {
		padding: $spacing * 6;
	}

	.p-xl-5 {
		padding: $spacing * 12;
	}

	.pt-xl-0 {
		padding-top: 0;
	}

	.pt-xl-1 {
		padding-top: $spacing * 1;
	}

	.pt-xl-2 {
		padding-top: $spacing * 2;
	}

	.pt-xl-3 {
		padding-top: $spacing * 4;
	}

	.pt-xl-4 {
		padding-top: $spacing * 6;
	}

	.pt-xl-5 {
		padding-top: $spacing * 12;
	}

	.pl-xl-0 {
		padding-left: 0;
	}

	.pl-xl-1 {
		padding-left: $spacing * 1;
	}

	.pl-xl-2 {
		padding-left: $spacing * 2;
	}

	.pl-xl-3 {
		padding-left: $spacing * 4;
	}

	.pl-xl-4 {
		padding-left: $spacing * 6 !important;
	}

	.pl-xl-5 {
		padding-left: $spacing * 12;
	}

	.pb-xl-0 {
		padding-bottom: 0;
	}

	.pb-xl-1 {
		padding-bottom: $spacing * 1;
	}

	.pb-xl-2 {
		padding-bottom: $spacing * 2;
	}

	.pb-xl-3 {
		padding-bottom: $spacing * 4;
	}

	.pb-xl-4 {
		padding-bottom: $spacing * 6;
	}

	.pb-xl-5 {
		padding-bottom: $spacing * 12;
	}

	.pr-xl-0 {
		padding-right: 0;
	}

	.pr-xl-1 {
		padding-right: $spacing * 1;
	}

	.pr-xl-2 {
		padding-right: $spacing * 2;
	}

	.pr-xl-3 {
		padding-right: $spacing * 4;
	}

	.pr-xl-4 {
		padding-right: $spacing * 6;
	}

	.pr-xl-5 {
		padding-right: $spacing * 12;
	}

	.my-xl-0 {
		margin-bottom: 0;
		margin-top: 0;
	}

	.my-xl-1 {
		margin-bottom: $spacing * 1;
		margin-top: $spacing * 1;
	}

	.my-xl-2 {
		margin-bottom: $spacing * 2;
		margin-top: $spacing * 2;
	}

	.my-xl-3 {
		margin-bottom: $spacing * 4;
		margin-top: $spacing * 4;
	}

	.my-xl-4 {
		margin-bottom: $spacing * 6;
		margin-top: $spacing * 6;
	}

	.my-xl-5 {
		margin-bottom: $spacing * 12;
		margin-top: $spacing * 12;
	}

	.mx-xl-0 {
		margin-left: 0;
		margin-right: 0;
	}

	.mx-xl-1 {
		margin-left: $spacing * 1;
		margin-right: $spacing * 1;
	}

	.mx-xl-2 {
		margin-left: $spacing * 2;
		margin-right: $spacing * 2;
	}

	.mx-xl-3 {
		margin-left: $spacing * 4;
		margin-right: $spacing * 4;
	}

	.mx-xl-4 {
		margin-left: $spacing * 6;
		margin-right: $spacing * 6;
	}

	.mx-xl-5 {
		margin-left: $spacing * 12;
		margin-right: $spacing * 12;
	}

	.py-xl-0 {
		padding-bottom: 0;
		padding-top: 0;
	}

	.py-xl-1 {
		padding-bottom: $spacing * 1;
		padding-top: $spacing * 1;
	}

	.py-xl-2 {
		padding-bottom: $spacing * 2;
		padding-top: $spacing * 2;
	}

	.py-xl-3 {
		padding-bottom: $spacing * 4;
		padding-top: $spacing * 4;
	}

	.py-xl-4 {
		padding-bottom: $spacing * 6;
		padding-top: $spacing * 6;
	}

	.py-xl-5 {
		padding-bottom: $spacing * 12;
		padding-top: $spacing * 12;
	}

	.px-xl-0 {
		padding-left: 0;
		padding-right: 0;
	}

	.px-xl-1 {
		padding-left: $spacing * 1;
		padding-right: $spacing * 1;
	}

	.px-xl-2 {
		padding-left: $spacing * 2;
		padding-right: $spacing * 2;
	}

	.px-xl-3 {
		padding-left: $spacing * 4;
		padding-right: $spacing * 4;
	}

	.px-xl-4 {
		padding-left: $spacing * 6;
		padding-right: $spacing * 6;
	}

	.px-xl-5 {
		padding-left: $spacing * 12;
		padding-right: $spacing * 12;
	}
}

@media screen and (min-width: 1400px) {
	.pl-x-xl-r {
		padding-right: 100px !important;
	}
}
