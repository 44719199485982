.calendar {
	width: 290px;
	margin: auto;
	&_week div {
		width: calc(100% / 7);
	}
	&_day {
		flex-wrap: wrap;
		display: flex;
		text-align: center;
	}
	&_day div {
		width: calc(100% / 7);
	}
}

.month {
	width: 85px;
}
