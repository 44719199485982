.padding-menu {
  padding-top: 32px;
}

.margin-text {
  margin-bottom: 20px;
}

.margin-text-skeleton {
  margin-top: 32px;
  margin-bottom: 41px;
}

.width-cerrar-icon {
  width: 20px;
}

.founder-info-card {
  width: 20em;
}
