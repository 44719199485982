@function calculateRem($size) {
	$remSize: $size / 16px;
	@return $remSize * 1rem;
}

@mixin font-size($size) {
	font-size: $size;
	font-size: calculateRem($size);
	line-height: calculateRem($size) * 1.5;
}

@mixin dropShadow() {
	box-shadow: -1px 4px 20px 3px rgba(0, 0, 0, 0.06);
}

@mixin dropShadowAll() {
	box-shadow: 0 2px 10px 4px rgba(0, 0, 0, 0.06) !important;
}

@mixin checkBoxType() {
	box-sizing: border-box;
	background-color: rgb(255, 255, 255);
	flex: 0 0 80px;
	box-shadow: 0px 2.66px 8px 0px rgba(0, 0, 0, 0.2);
	outline: none;
	color: $secondNeutral60;
	font-family: $robotoRegular;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 16px;
}

@mixin checkBoxaButtonNum($background, $color) {
	background: $background;
	color: $color;
	border: none;
	outline: none;
	cursor: pointer;
}

@mixin width-height($width, $height) {
	width: $width;
	height: $height;
}

@mixin switchButtons($background, $color, $family) {
	background-color: $background;
	color: $color;
	font-family: $family;
}

@mixin modalStyles() {
	width: 100%;
	max-width: 260px;
	background-color: white;
	margin: auto;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	border-radius: 10px;
	outline: none;
}
