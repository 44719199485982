.prox-card{
    background-color: $firstPrimary10;
    width: 261px;
    padding: 2px 0 1px 0;
}

.inner-prox-card{
    padding: 2px 0 1px 0;
    border-bottom: dotted 2px;
    padding-right: 20px;
    padding-left: 20px;
    border-top: dotted 2px;
    border-color: $secondPrimary50;
}

.image-news-card{
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
    border-radius: 2px;
}

.card-vertical-news{
    width: 220px !important;
    height: 350px !important;
}

.h-34{
    height: 38px;
}

.h-38{
    height: 38px;
}


.h-85{
    height: 85px;
}

.h-371{
    height: 350px;
}

.overflow-scroll{
    overflow-y: scroll;
}