
  

  .image-container {
    background-color: #C4C4C4;
    overflow: hidden;
    position: relative;
    min-height: 200px;
    max-height: 200px;
    object-fit: cover;
    border-radius: 10px;
    opacity: 1;
    &.isLoadedTran {
      transition: opacity 1s ease-out;
      opacity: 0;
      display: none;

    }
  }
  .image {
    opacity: 0;
    position: relative;
    &.thumb {
      opacity: 1;
      filter: blur(10px);
      position: absolute;
      object-fit: cover;
      max-width: 600px;
      transition: opacity 0.5s ease-out;
      &.isLoaded {
        opacity: 0;
        display: none;

      }
    }
  
    &.isLoaded {
      transition: opacity 1s ease-out;
      opacity: 1;
    }
  }
  


  

  .image-container-multiple {
    background-color: #C4C4C4;
    overflow: hidden;
    position: relative;
    min-height: 200px;
    object-fit: cover;
    border-radius: 10px;
  }
  .image {
    opacity: 0;
    height: auto;
    &.thumb {
      opacity: 1;
      filter: blur(10px);
      transition: opacity 1s ease-out;
      position: absolute;
      &.isLoaded {
        opacity: 0;
      }
    }
  
    &.isLoaded {
      transition: opacity 1s ease-out;
      opacity: 1;
    }
  }
  

  