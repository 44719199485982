.filter-label-ocean {
    padding: 4px 16px;  
    display: inline-block;
    background: white;
    color: $firstPrimary60;
    border-radius: 15px;
    border: 2px solid $Ocean30;
    outline: none;
    cursor: pointer;
    font-family: $robotoRegular;
  }
  .flex-flow-colum {
    flex-flow:column
  }
  .align-baseline{
    align-items: baseline
  }
  .MuiAvatar-root {
    width: 48px !important;
    height: 48px !important;
  }
.r-20{
    border-radius: 20px;

}
.text-des{
    font-size: 18px;
}
  .size-card-w{
      width: 128px;
  }
  .size-card-h-small{
    height: 82px;
}
.size-card-h-big{
    height: 164px;
}
div .card{
    border-radius: 10px;
 
}
.h-72{
    height: 72px;

}
.justify-content-evenly{
    justify-content: space-evenly;
}
.size-icon{
    height: 24.5pt;
    width: 24.5pt;
}
.w-8{
    width: 82%;
}

.dotted-container{
  border-top: dotted;
  margin: 2px 14px;
  border-bottom: dotted;
  border-color: rgb(176, 220, 239);
  border-width: 3px;
}

.animation{
  position: absolute;
  top: 451px;
  left: 160px;
}

.fade-out{
  animation: fadeOut ease 0.2s;
  -webkit-animation: fadeOut ease 0.2s;
  -moz-animation: fadeOut ease 0.2s;
  -o-animation: fadeOut ease 0.2s;
  -ms-animation: fadeOut ease 0.2s;
}

@keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
} 
}

@media screen  and (max-width: 360px){
  .size-card-w{
      width: 100px;
  }
}

@media screen  and (min-width: 768px){
  .height3-prueba{
    height: 148px !important;
  }

  .mt-n20{
    margin-top: -14px;
  }

  .mt-n9{
    margin-top: -9px;
  }

  .dotted-container{
    border-top: dotted;
    margin: 2px 26px;
    border-bottom: dotted;
    border-color: rgb(176, 220, 239);
    border-width: 3px;
  }

  .calc-width-prueba{
    width: calc(100% - 104px);
  }
  .space-m{
      margin-left: 28px;
      margin-top: 24px;
      padding-left: 16px;
  }
  .space-mt{
     
      margin-top: 24px;
  }
  .size-card-w {
      width: 243px;
  }
  .page-height{
    height: calc(100vh - 385px) !important;
  }

  .ml-4-prueba{
    margin-left: $spacing * 6;
  }
  .height1-prueba{
    height: 112px !important;
  }
  .height2-prueba{
    height: 190px !important;
  }
  .siguiendo{
    width: 29px !important;
    height: 29px !important;
  }
  .animation{
    position: absolute;
    top: 771px;
    left: 350px;
  }
}

@media screen  and (min-width: 992px){
  .space-m{
      margin-left: 0px !important;
      margin-top: 0px !important;
      padding-left: 0px !important;
  }
  .space-mt{
     
      margin-top: 0px !important;
  }
  
  .page-height{
    height: calc(100vh - 211px) !important;
  }

  .animation{
    position: absolute;
    top: 491px;
    left: 560px;
  }
  
}

@media screen  and (min-width: 1024px){
  .size-card-w {
      width: 202px !important;
  }
}

@media screen  and (min-width: 1200px){
  .height3-prueba{
    height: 148px !important;
  }

  .animation{
    position: absolute;
    top: 371px;
    left: 740px;
  }

  .dotted-container{
    border-top: dotted;
    margin: 2px 26px !important;
    border-bottom: dotted;
    border-color: rgb(176, 220, 239);
    border-width: 2px !important;
  }
  .py-0-5{
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .r-pc {
      margin-right: $spacing * 2.5;
  }
  .l-pc {
    margin-left: $spacing * 2.5;
  }
  .height1-prueba{
    height: 112px !important;
  }
  .height2-prueba{
    height: 190px !important;
  }
  .calc-width-prueba{
    width: calc(100% - 105px);
  }
  .calc-width-prueba-sk{
    width: calc(100% - 106px);
  }
}
 
  @media screen  and (min-width: 1240px){
    .size-card-w {
        width: 270px !important;
    }
    .page-height{
      height: calc(100vh - 185px) !important;
    }
  }
  
  
  

  .close{
    width: 20px;
    height: 20px;
  }

  .siguiendo{
    width: 24px;
    height: 24px;
  }

  .seguidor{
    margin-top: -10px !important;
  }

  .lapiz{
    width: 20px;
    height: 20px;
  }

  .perfil{
    width: 44px;
    height: 44px;
  }

  .ml-22{
    margin-left: $spacing * 22;
  }

  .ml-35{
    margin-left: $spacing * 32;
  }

  .ml-36{
    margin-left: $spacing * 36.5;
  }

  .ml-7-prueba{
    margin-left: $spacing *7;
  }

  .ml-9-prueba{
    margin-left: $spacing * 9;
  }

  .ml-11-prueba{
    margin-left: $spacing * 11;
  }

  .mr-7-prueba{
    margin-right: $spacing * 7;
  }

  .mr-7-pruebas{
    margin-right: $spacing * 8;
  }

  .mt-5-prueba{
    margin-top: $spacing * 5;
  }

  .ml-auto-prueba{
    margin-left: auto;
  }

  .line-prueba{
    background-color: $gray;
    height: 1px;
  }

  .a-text-medium-gray-prueba {
    color: $secondNeutral70;
    font-family: $robotoMedium;
  }

  .font18-prueba {
    font-size: 18px !important;
  }

  .a-text-name-prueba{
    color: #355471 !important;
    font-family: $robotoMedium;
  }

  .box-shadow-prueba{
    box-shadow: 0 2px 7px 0 rgba( 0, 0, 0, .1);
  }

  .height1-prueba{
    height: 66px;
  }

  .height2-prueba{
    height: 138px;
  }

  .height3-prueba{
    height: 101px;
  }