.bcolor-gray{
    background-color: $gray;
}

.input-checkbox + .btn-idiomas{
    background: $firstNeutral10;
}

.input-checkbox:checked + .btn-idiomas {
    background: $secondPrimary80;
}

.btn-idiomas{
    border-radius: 50px !important;
    font-size: 14px !important;
    color: $firstPrimary60;
}

.modal-btn {
    background-color: white;
    border: none;
    cursor: pointer;
}

.camera-position{
    top: 11px;
    right: 15px;
    cursor: pointer;
}

.text-second-neutral-90{
    color: $firstPrimary60;
}

.text-red{
    color: $red60;
}

#profile-pic{
    max-height: 48px !important;
    max-width: 48px !important;
    height: 48px !important;
    width: 48px !important;
}
.mr-41{
    margin-right: 141px;
}
@media screen and (min-width: 768px) {
    .camera-position{
        bottom: 16px;
        left: 13px;
    }
    #profile-pic{
        max-height: 64px !important;
        max-width: 64px !important;
        height: 64px !important;
        width: 64px !important;
    }
}

@media screen and (min-width: 992px) {
    .profile-form-vh100{
        // height: calc(100vh - 88px - 24px - 24px);
        min-height: calc(638px + 32px + 32px);
    }

    .btn-idiomas{
        border-radius: 50px !important;
        font-size: 16px !important;
        color: $firstPrimary60;
    }
}

@media screen and (min-width: 1200px) {
    .profile-form-vh100{
        // height: calc(100vh - 88px - 24px - 24px);
        min-height: calc(442px + 32px + 32px);
    }
    .btn-idiomas:hover, .input-checkbox:checked + .btn-idiomas {
        background: $secondPrimary80;
    }
}