$firstPrimary70: #2e4a63;
$firstPrimary75: #3b586d;
$firstPrimary60: #355471;
$firstPrimary50: #436a8e;
$firstPrimary40: #e8f7f3;
$firstPrimary30: #c5e4ea;
$firstPrimary10: #dceff3;

$firstPrimary75: #3b586d;

$secondPrimary80: #ddeff3;
$secondPrimary70: #116b9b;
$secondPrimary60: #147db5;
$secondPrimary50: #23a2e7;
$secondPrimary40: #42b0ea;

$secondPrimary30: #97aabe;
$secondPrimary10: #a1d7f5;

$firstNeutral100: #6f736f;
$firstNeutral90: #8b8f8b;
$firstNeutral80: #a8aba8;
$firstNeutral60: #b3b6b3;
$firstNeutral50: #e1e2e1;
$firstNeutral40: #c4c4c4;
$firstNeutral30: #ecedec;
$firstNeutral20: #f0f1f0;
$firstNeutral10: #f4f4f4;

$secondNeutral100: #222422;
$secondNeutral90: #292b29;
$secondNeutral70: #303230;
$secondNeutral60: #373937;
$secondNeutral40: #686c68;
$secondNeutral30: #4f524f;

$Ocean30: #c5e4ea;

$red60: #c53929;

$red70: #ac3224;

$green: #3bb54a;

$gray: #c4c6c4;

$black: #000000;

$thirdNeutral: white;
