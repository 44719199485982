.user-list__trash-icon {
  margin-left: 24px !important;
}
.Content_Container__xhjD8 {
  position: absolute;
  z-index: 5;
  display: block;
  max-width: 80vw;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px;
  color: inherit;
  width: 130px !important;
  margin: 64px 0 0 106px !important;
  padding: 8px  !important;
  border-radius: 10px !important;
  border: solid 1px $firstNeutral80 !important;
}
.Content_Triangle__2lYqP {
  display: none;
}
.Content_Cancel__VinjW svg{
  display: none;
}