* {
    font-family: "Roboto-regular";
}

a {
    text-decoration: none;
    color: #355471 !important;
}

.divider {
    display: none;
}

.sectionCard {
    background-color: white;
    padding: 40px;
    border-radius: 15px;
    margin: 24px;
    margin-bottom: 50px;
    justify-content: center;
    box-shadow: 0 2px 10px 4px  rgba(0, 0, 0, 0.06);
    > h1 {
	font-size: 1.5em;
	font-family: "Roboto-Medium" !important;
	font-weight: bolder;
	margin-bottom: 16px;
    }
}


.card-child {
    display:grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px 20px;
    grid-template-areas: 
    "article item item item"
    "article item item item"
    "more more more more";
}

article {
 border-radius: 15px;
    box-shadow: 0 2px 10px 4px rgba(0, 0, 0, 0.06);   
}

.card-child > article:first-child{
    grid-area: article;
}

.card-child > div:nth-child(2) {
    grid-area: item;
}

.card-child > a:last-child {
    grid-area: more;
}

.ver-mas {
    grid-row: 2;
    color: #355471;
    margin-top: 10px;
}

.news-category-list {
    font-size: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 40px;
    color: #355471 !important;
    font-weight: 800;
    line-height: 1.5;
}


.skeleton-podcast {
    width: 100%;
    height: 100%;
}

$desktop: "(min-width: 1200px)";
$ipadPro: "(min-width: 992px)";
$ipadMini: "(max-width: 768px)";
$phone: "(max-width: 500px)";

@media #{$ipadMini} {
    .sectionCard > h1 {
	font-size: 1.2em;
    }
    .card-child {
	display: flex;
	flex-direction: column;
    }
}

@media #{$phone} {
    .sectionCard {
	padding: 0;
	box-shadow: none;
    }
    .sectionCard > p {
	font-size: 20px;
    }
    .divider {
	display:block;
	text-align: center;
	color: #47AFEA;
	opacity: 0.8;
	margin: 0 50px 0 50px;
    }
}
	  
