.progress-bar{
    height: 5px;
  -webkit-appearance: none;
  width: 100%;
  border-radius: 5px;
  // background: #3b7677;
  transition: background 0.2s ease;
  cursor: pointer;
}

.progress-bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 5px;
  height: 5px;
}

.pruebaPosicion{
  position: fixed;
  width: 100%;
  height: 50px;
  background-color: red;
  bottom: 0px;
  margin-bottom: 0px;
}

.img-cover{
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background-color: white;
}

.audio-controller{
  height: 80px;
  background-color: #355471;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
}

// #circularProgress > .MuiCircularProgress-colorPrimary {
//   color: #355471 !important;
// }

#linearProgress > .MuiLinearProgress-colorPrimary {
  background-color: white !important;
}

#linearProgress > * > .MuiLinearProgress-barColorPrimary{
  background-color: #23a2e7 !important;
}
.container-podcast-description{
  font-family: $robotoRegular !important;
  line-height: 1.5 !important;
  color: $secondNeutral60 !important;
}

.background-prueba{
  background-color: #F4FBFD;
}

@media screen and (max-width: 767px) {
  .audio-controller{
    height: 60px;
    background-color: #355471;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
  }

  .img-cover{
    width: 40px;
    height: 40px;
    border-radius: 5px;
  }
}