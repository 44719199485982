.hashtags {
  display: flex;
  flex-direction: column;
  font-family: inherit;
  gap: 4px;

  label {
    font-weight: 500;
    font-size: 15px;
  }

  input {
    padding: 8px 16px;
    border-radius: 7px;
    font-size: 16px;
  }

  img {
    width: 9px;
  }
}

.hashtagsField {
  border: 1px solid #001B48;
}

.hashtagInput {
  color: #001B48;
  border: 1px solid transparent;
  background-color: #D6E7EE;
}

.hashtagsInputContainer {
  border: 1px solid #a8aba8;
  border-radius: 5px;
  padding: 7px 18px;
  width: 100%;
}

.hashtagsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  div {
    position: relative;

    button {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      color: #001B48;
      display: flex;
      padding: 8px;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      cursor: pointer;
      border: none;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

}