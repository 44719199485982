.a-button-primary1 {
	border-radius: 5px;
	font-family: $robotoBold;
	@include checkBoxaButtonNum($firstPrimary60, $thirdNeutral);
}

.arrow {
	height: 20px;
	width: 20px;
}

.a-button-primary1:hover,
.a-button-primary1:focus {
	background: $firstPrimary70;
}

.border-color-primary {
	border: 1px;
	border-color: $firstPrimary60;
}

.a-button-primary1:active {
	background-color: $firstPrimary50;
}

.a-button-primary2 {
	border-radius: 5px;
	font-family: $robotoBold;
	@include checkBoxaButtonNum($secondPrimary50, $thirdNeutral);
}

.a-button-secondary {
	@include checkBoxaButtonNum($firstNeutral30, $firstPrimary60);
}

.a-button-secondary:hover {
	background-color: $firstNeutral50;
}

.a-button-primary2:hover,
.a-button-primary2:focus {
	background: $secondPrimary60;
}

.a-button-primary2:active {
	background-color: $secondPrimary40;
}

.a-button-danger-60 {
	border-radius: 5px;
	font-family: $robotoBold;
	@include checkBoxaButtonNum($red60, $thirdNeutral);
}

.a-button-danger-60:hover,
.a-button-danger-60:focus {
	background: $red70;
}

.a-button-light {
	border-radius: 5px;
	border: 2px solid $firstNeutral80;
	font-family: $robotoBold;
	@include checkBoxaButtonNum($thirdNeutral, $secondNeutral90);
}

.a-button-light:hover,
.a-button-light:focus {
	background: $firstNeutral10;
}

.a-button-light-blue {
	border-radius: 5px;
	border: 2px solid $firstPrimary50;
	font-family: $robotoBold;
	@include checkBoxaButtonNum($thirdNeutral, $firstPrimary50);
}

.a-button-light-blue-disable {
	background: $thirdNeutral;
	color: $secondNeutral60;
	border-radius: 5px;
	border: 2px solid $secondNeutral60;
	outline: none;
	font-family: $robotoBold;
	opacity: 30%;
}

.a-button-light-blue:hover,
.a-button-light-blue:focus {
	background: $firstNeutral10;
}

.btn-checkbox {
	height: 32px;
	border-radius: 5px;
	font-family: $robotoRegular;
	//display: inline-block;
	display: flex;
	justify-content: center;
	align-items: center;
	@include checkBoxaButtonNum($firstNeutral30, $secondNeutral60);
}

.btn-checkbox-form {
	border-radius: 50px;
	font-family: $robotoRegular;
	padding: 8px 15px;
	//height: 32px;
	border: 1px solid #c5e4ea !important;
	display: inline-block;
	// flex-wrap: nowrap;
	// overflow: scroll;
	// display: flex;
	// flex-wrap: wrap;
	// flex-direction: column;
	// justify-content: center;
	// align-items: center;
	@include checkBoxaButtonNum($thirdNeutral, $firstPrimary60);
}

* {
	-ms-overflow-style: none;
}

::-webkit-scrollbar {
	display: none;
}

.btn-checkbox-search {
	border-radius: 50px;
	font-family: $robotoRegular;
	padding: 2px 20px;
	border: 1px solid #c5e4ea !important;
	display: flex;
	height: 25px;
	align-items: center;
	justify-content: center;
	// margin: 0 20px;
	// flex-direction: column;
	// justify-content: center;
	// align-items: center;
	@include checkBoxaButtonNum($thirdNeutral, $firstPrimary60);
}

.btn-checkbox-form-inactive {
	border-radius: 50px;
	font-family: $robotoRegular;
	padding: 8px 15px;
	//height: 32px;
	border: 1px solid $firstNeutral80 !important;
	display: inline-block;
	// display: flex;
	// flex-wrap: wrap;
	// flex-direction: column;
	// justify-content: center;
	// align-items: center;
	@include checkBoxaButtonNum($thirdNeutral, $firstNeutral80);
}

.switch-question-check {
	@include checkBoxType;
	height: auto;
	width: auto;
	border: 1px solid $Ocean30;
}

.filter-blog-topic {
	@include checkBoxType;
	height: auto;
	width: auto;
	border: 1px solid $Ocean30;
}

.switch-question-check-none {
	@include checkBoxType;
	height: auto;
	width: auto;
	border: 1px solid $secondNeutral40;
	cursor: not-allowed !important;
	opacity: 0.5;
}

.filter-blog-topic:hover,
.input-checkbox-textBlog:checked,
.filterCheckBlog {
	background-color: #c5e4ea;
}

.input-checkbox-textBlog:checked+.filter-blog-topic {
	background-color: #c5e4ea;
}

.switch-question-check:hover,
.input-checkbox-textTopic:checked+.filterCheck {
	background: $Ocean30 !important;
}

.btn-checkbox:hover,
.input-checkbox:checked+.btn-checkbox {
	background: $firstNeutral60;
}

.input-checkbox:checked+.btn-checkbox-form {
	background: $firstPrimary30;
}

.input-checkbox:checked+.btn-checkbox-form-inactive {
	background: $firstNeutral20;
}

.input-checkbox+.btn-checkbox {
	background: $firstNeutral30;
}

@media screen and (min-width: 768px) and (max-width: 992px) {
	.btn-checkbox {
		height: 40px;
	}
}

.btn-checkbox-form {
	border-radius: 50px;
	font-family: $robotoRegular;
	padding: 8px 15px;
	//height: 32px;
	border: 1px solid #c5e4ea !important;
	display: inline-block;
	// display: flex;
	// flex-wrap: wrap;
	// flex-direction: column;
	// justify-content: center;
	// align-items: center;
	@include checkBoxaButtonNum($thirdNeutral, $firstPrimary60);
}

.btn-checkbox-sm {
	background: #ffffff;
	border-radius: 5px;
	border: none;
	outline: none;
	color: $firstPrimary60;
	font-family: $robotoRegular;
	margin-top: 8px;
	display: inline-block;
}

.btn-checkbox-sm-inactive {
	background: #ffffff;
	border-radius: 5px;
	border: none;
	outline: none;
	color: $firstNeutral80;
	font-family: $robotoRegular;
	margin-top: 8px;
	display: inline-block;
}

.btn-checkbox-img {
	@include checkBoxType;
	margin-right: 8px;
	border-radius: 5px;
	width: 80px;
	border: 0.93px solid rgb(210, 223, 235);

	//opacity: .5; //Otra clase
}

.btn-checkbox-img-blog {
	@include checkBoxType;
	margin-right: 8px;
	border-radius: 15px;
	width: 60px;
	height: 60px;
	border: 0.93px solid rgb(210, 223, 235);
	margin-bottom: 0px !important;
	//opacity: .5; //Otra clase
}

.btn-checkbox-icon {
	@include checkBoxType;
	flex-direction: column;
	margin-right: 4px;
	border-radius: 10px;
	height: 80px;
	width: 96px;

	border: 1px solid rgb(220, 239, 243);
	opacity: 1;
	//opacity: .5; //Otra clase
}

.btn-checkbox-cidUser {
	opacity: 0.5;
	//opacity: .5; //Otra clase
}

.btn-checkbox-icon-text {
	@include checkBoxType;
	height: auto;
	width: auto;
	border: 1px solid $secondPrimary50;

	//opacity: .5; //Otra clase
}

.btn-checkbox-icon-text:hover,
.input-checkbox-topic:checked+.btn-checkbox-icon-add-topic {
	background-color: #dceff3;
	border: 1px solid $secondPrimary50;
	opacity: 1 !important;
}

.input-checkbox-topic+.btn-checkbox-icon-add-topic {
	border: 1px solid rgb(210, 223, 235);
}

.btn-checkbox-icon:hover,
.input-checkbox-icon:checked+.btn-checkbox-icon-add {
	border: 1px solid $secondPrimary60;
	opacity: 1 !important;
}

.input-checkbox-topic+.btn-checkbox-icon-add-topic {
	border: 1px solid rgb(210, 223, 235);
}

.btn-checkbox-cidUser:hover,
.input-checkbox-cidUser:checked+.btn-checkbox-icon-cid {
	border: 1px solid $firstPrimary70;
	opacity: 1 !important;
}

.input-checkbox-icon:checked~* .checkbox-Icon {
	opacity: 1 !important;
}

.input-checkbox-icon:checked~* .checkbox-icon-text {
	opacity: 1 !important;
}

.input-checkbox-img-blog:checked+.btn-checkbox-img-add-blog {
	opacity: 1 !important;
	box-shadow: none;
	border: 1px solid $secondPrimary50;
}

.input-checkbox-img-blog+.btn-checkbox-img-add-blog {
	opacity: 0.5;
	border: 0.93px solid rgb(210, 223, 235);
	box-shadow: 0px 2.66px 8px 0px rgba(0, 0, 0, 0.2);
}

.btn-checkbox-img-add:hover,
.input-checkbox-img:checked+.btn-checkbox-img-add {
	opacity: 1;
	box-shadow: none;
	border: 1px solid $secondPrimary50;
}

.input-checkbox-img+.btn-checkbox-img-add {
	opacity: 0.5;
	border: 0.93px solid rgb(210, 223, 235);
	box-shadow: 0px 2.66px 8px 0px rgba(0, 0, 0, 0.2);
}

.input-checkbox:checked+.btn-checkbox-search {
	background: $firstPrimary30;
}

.background-filter-labels {
	background: $secondPrimary80;
	display: inline;
}

.checkbox-img-container {
	display: flex;
	flex-wrap: wrap;
}

.checkbox-text-container {
	display: flex;
	flex-wrap: wrap;
}

.btn-checkbox-img-sm {
	background-color: rgb(255, 255, 255);
	width: 80px;
	height: 80px;
	border-radius: 5px;
	border: 0.93px solid rgb(210, 223, 235);
	box-shadow: 0px 2.66px 8px 0px rgba(0, 0, 0, 0.2);
	outline: none;
	color: $secondNeutral60;
	display: flex;
	justify-content: center;
	align-items: center;
	//margin: 8px;
	margin-bottom: 16px;
}

.checked {
	background: $firstPrimary10;
	border-radius: 5px;
	color: $secondNeutral60;
	font-family: $robotoRegular;
	display: inline-block;
}

.checked-gray {
	background: $firstNeutral10;
	border-radius: 25px;
	color: $secondNeutral70;
	font-family: $robotoRegular;
	display: inline-block;
}

.modal-img {
	height: 100%;
	width: 100vw;
	background-color: #dceff3;
}

.a-button-primary-disabled {
	background: $firstNeutral50;
	color: $thirdNeutral;
	border-radius: 5px;
	border: none;
	outline: none;
	font-family: $robotoBold;
	pointer-events: none;
}

.a-button-gray-1 {
	border-radius: 5px;
	@include checkBoxaButtonNum($firstNeutral50, $secondNeutral60);
	font-family: $robotoMedium;
}

.a-button-topic-gray-1 {
	border-radius: 5px;
	@include checkBoxaButtonNum($firstNeutral50, $firstPrimary70);
	width: 50%;
	height: 36px;
}

.a-button-33h {
	height: 33px !important;
	width: 98px !important;
}

.a-button-topic-gray-2 {
	border-radius: 5px;
	@include checkBoxaButtonNum($firstNeutral50, $firstPrimary70);
}

.a-button-topic-gray-2:hover,
.a-button-topic-gray-2:focus {
	background: $firstNeutral60;
}

.a-button-topic-gray-2:active {
	background: $firstNeutral30;
}

.btn-asign {
	width: 120px !important;
}

.a-button-gray-1:hover,
.a-button-gray-1:focus,
.a-button-topic-gray-1:hover,
.a-button-topic-gray-1:focus {
	background: $firstNeutral60;
}

.a-button-gray-1:active,
.a-button-topic-gray-1:active {
	background: $firstNeutral30;
}

.a-button-gray-2 {
	border-radius: 5px;
	@include checkBoxaButtonNum($firstPrimary60, $thirdNeutral);
	font-family: $robotoMedium;
}

.a-button-gray-2:hover,
.a-button-gray-2:focus {
	background: $firstNeutral80;
}

.a-button-gray-2:active {
	background: $firstNeutral50;
}

//Button blue 2 con más border radius
.a-button-blue-2 {
	border-radius: 10px;
	@include checkBoxaButtonNum($firstPrimary60, $thirdNeutral);
}

.a-button-blue-2:hover,
.a-button-blue-2:focus {
	background: $firstPrimary70;
}

.a-button-blue-2:active {
	background: $firstPrimary50;
}

.a-button-blue-1 {
	border-radius: 5px;
	@include checkBoxaButtonNum($firstPrimary60, $thirdNeutral);
}

.a-button-topic-blue-1 {
	border-radius: 5px;
	@include checkBoxaButtonNum($firstPrimary60, $thirdNeutral);
	width: 45%;
	height: 36px;
}

.a-button-blue-1:hover,
.a-button-blue-1:focus,
.a-button-topic-blue-1:hover,
.a-button-topic-blue-1:focus {
	background: $firstPrimary70;
}

.a-button-blue-1:active,
.a-button-topic-blue-1:active {
	background: $firstPrimary50;
}

.a-button-invisible {
	position: absolute;
	width: 100%;
	height: 60%;
	top: 40%;
	left: 0;
}

.a-button-topic-gray-3 {
	@include checkBoxaButtonNum($firstNeutral30, $firstPrimary70);
	border-radius: 5px;
	font-family: $robotoMedium;
}

.button-ver-video-overview {
	@include checkBoxaButtonNum($thirdNeutral, $firstPrimary60);
	border-radius: 5px;
	font-family: $robotoMedium;
	border: 2px solid $firstPrimary60;
}

.button-ver-video-overview-inactivo {
	@include checkBoxaButtonNum($thirdNeutral, $firstNeutral30);
	border-radius: 5px;
	font-family: $robotoMedium;
	border: 2px solid $firstNeutral30;
}

.button-form {
	@include checkBoxaButtonNum($thirdNeutral, $firstPrimary60);
	border-radius: 5px;
	font-family: $robotoMedium;
	border: 1px solid $firstPrimary60;
}

.button-form-inactive {
	@include checkBoxaButtonNum($thirdNeutral, $firstNeutral80);
	border-radius: 5px;
	font-family: $robotoMedium;
	border: 1px solid $firstNeutral80;
}

@media screen and (min-width: 768px) {
	.btn-checkbox-icon {
		height: 100px;
		width: 110px;
		margin-right: 16px;
	}

	.a-button-33h {
		height: 33px !important;
	}
}

@media screen and (max-width: 768px) {

	.btn-checkbox-img,
	.btn-csheckbox-img-sm {
		width: 80px;
		height: 80px;
	}
}

@media screen and (min-width: 768px) {

	.btn-checkbox-img,
	.btn-checkbox-img-sm {
		width: 80px;
		height: 80px;
		margin-right: 16px;
	}

	.a-button-topic-blue-1,
	.a-button-topic-gray-1,
	.btn-asign {
		width: 150px !important;
		height: 40px;
	}
}

@media screen and (min-width: 992px) {

	.btn-checkbox-img,
	.btn-checkbox-img-sm {
		width: 112px;
		height: 112px;
	}

	.btn-checkbox-icon {
		height: 80px;
		width: 116px;
		margin-right: 16px;
	}

	.a-button-topic-blue-1 {
		height: 33px;
		width: 150px;
	}

	.a-button-topic-gray-1 {
		height: 33px;
		width: 150px;
	}
}

@media screen and (min-width: 1200px) {

	.btn-checkbox-img,
	.btn-checkbox-img-sm {
		width: 112px;
		height: 112px;
	}

	.btn-checkbox-icon {
		height: 80px;
		width: 140px;
		margin-right: 18px;
	}

	.a-button-topic-blue-1 {
		height: 33px;
		width: 150px;
	}

	.a-button-topic-gray-1 {
		height: 33px;
		width: 150px;
	}
}