.sign-in-link {
  border-bottom: 1px solid $firstNeutral90;
}

.sign-in-image {
  width: 500px;
}

.sign-in-background {
  background-color: $firstNeutral20;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .sign-in-content {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sign-in-container {
    background-color: white;
    padding: 0px 24px 48px 24px;
    border-radius: 10px;
    @include dropShadow();
  }
}

@media screen and (min-width: 1200px) {
  .sign-in-content {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
  }
}
