.position-relative {
	position: relative;
}

.position-absolute {
	position: absolute !important;
}

.position-fixed {
	position: fixed;
}

.float-right {
	float: right !important;
}

.float-left {
	float: left !important;
}
.top-0 {
	top: 0;
}

.top {
	top: 50%;
}
.right {
	right: 0;
}
.right-50 {
	right: 43%;
}

.left-0 {
	left: 0;
}

.top-separator-1 {
	top: 22% !important;
	padding-top: 5px !important;
}
.top-separator-3 {
	top: 16% !important;
	padding-top: 5px !important;
	margin-top: 2px;
}
.separator {
	position: absolute;
	height: 32px;
	width: 2px;
	background-color: $firstNeutral60;
	left: 12%;
	top: 35%;
	padding-top: 2px;
}
.separator-mini {
	position: absolute;
	height: 50px;
	width: 3px;
	background-color: $firstNeutral60;
	left: 13%;
	top: 22%;
	display: none;
}
@media screen and (min-width: 768px) {
	.float-md-right {
		float: right !important;
	}

	.float-md-left {
		float: left !important;
	}
	.separator-mini {
		position: absolute;
		height: 46px;

		display: block;
		left: 10%;
		top: 17%;
	}
}
@media screen and (min-width: 992px) {
	.float-lg-right {
		float: right !important;
	}

	.float-lg-left {
		float: left !important;
	}
}
@media screen and (min-width: 1200px) {
	.float-xl-right {
		float: right !important;
	}

	.float-xl-left {
		float: left !important;
	}
	.top-separator-1 {
		top: 28% !important;
		padding-top: 5px !important;
	}
}

.align-flex-end {
	align-items: flex-end;
}
