.sign-in-admin-content {
	background-color: $firstNeutral20;
	height: 100vh;
}

.sign-in-admin-container {
	max-width: 320px;
	margin: auto;
}

@media screen and (min-width: 768px) {
	.sign-in-admin-content {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
@media screen and (min-width: 992px) {
	.sign-in-admin-content {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
@media screen and (min-width: 1200px) {
	.sign-in-admin-content {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
