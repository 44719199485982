.sidebar {
  position: fixed;
  background: $thirdNeutral;
  left: -100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden; 
  z-index: 10;
  max-width: 78.67%;
  width: 100%;
  transition: 500ms;
  &__logo {
    width: 96px;
  }
  &__icon {
    width: 19px;
  }
}

.sidebar-active {
  left: 0%;
}

.invisible-button {
  max-width: 33.333333%;
  position: absolute;
  background: transparent;
  width: 100%;
  height: 100%;
  z-index: 1;
  right: 100%;
}

.invisible-button-active {
  right: 0%;
}

@media screen and (min-width: 768px) {
  .sidebar {
    max-width: 41.666667%;
  }

  .invisible-button {
    max-width: 58.333333%;
  }
}

@media screen and (min-width: 992px) {
  .sidebar {
    max-width: 33.333333%;
  }

  .invisible-button {
    max-width: 66.666667%;
  }
}
