.input {
	background-color: $thirdNeutral;
	border-radius: 5px;
	border: 1px solid $firstNeutral80 !important;
	outline: none;
}
.input-topic {
	border-radius: 10px;
	border: 0.5px solid $firstNeutral80;
	outline: none;
	width: 100%;
}
.text:focus,
.textarea:focus {
	color: $secondNeutral70;
}

::placeholder {
	color: $firstNeutral80;
	font-size: 16px;
}

.input-search {
	border-radius: 50px;
	outline: none;
	border: none;
	color: $secondNeutral60;
	height: 30px;
}

.input-search-2 {
	background-color: $firstNeutral20;
	border-radius: 50px;
	outline: none;
	border: none;
	color: $secondNeutral60;
	height: 30px;
	&_icon {
		height: 15px;
	}
}

.input:focus {
	border: 1px solid $firstPrimary60 !important;
}

.input-error {
	border: 1px solid $red70 !important;
}

.input-correct {
	border: 1px solid $green;
}
.MuiBadge-colorPrimary {
	background-color: $secondPrimary50 !important;
}

.textarea {
	background-color: $thirdNeutral;
	border-radius: 5px;
	border: 1px solid $firstNeutral80;
	outline: none;
	resize: none;
}

.select {
	background-color: $thirdNeutral;
	border-radius: 5px;
	border: 1px solid $firstNeutral80;
	outline: none;
}

.input-basic {
	background: transparent;
	border: none;
	outline: none;
}

.z-index-1 {
	z-index: 1;
}

.position-absolute-select {
	position: absolute;
	top: 10px;
}

.input-option {
	background-color: $thirdNeutral;
	border: 1px solid $firstNeutral80;
	outline: none;
}

.input-option li {
	list-style: none;
	margin-left: -8px !important;
	padding-left: 8px;
}

.input-option li:hover {
	cursor: pointer;
}
.btn-checkbox-form{
	// Removes text selection
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.checkbox-img {
	width: 60px;
	height: auto;
	//object-fit: contain;
}

.checkbox-img-blog {
	width: 30px;
	height: auto;
	//object-fit: contain;
}

.checked-img-blog-portada {
	border: 1px solid #23a2e7;
}

.unchecked-img-blog-portada {
	opacity: 0.6;
	border: 0.93px solid #d2dfeb;
}

.checkbox-img-text {
	text-align: center;
	font-family: $robotoMedium;
}

.checkbox-img-text-sm {
	text-align: center;
	font-family: $robotoRegular;
	color: #355471;
	display: inline-block;
	margin-bottom: 16px;
}

.checkbox-icon-text {
	text-align: center;
	font-family: $robotoRegular;
	color: $firstPrimary60;
	opacity: 0.6;
}

input[type='radio'] {
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	margin: 0;
}

.radioNews {
  width: 18px;
  height: 18px;
  // border-radius: 50%;
  border: 2px solid $firstPrimary60;
  margin-bottom: -5px;
}

.radioNews:checked:before {
  background: #355471;       
} 

.radio {
	-webkit-appearance: none;
	appearance: none;
	width: 18px;
	height: 18px;
	border-radius: 25%;
	border: 2px solid $secondNeutral100;
	margin-bottom: -5px;
}

.radio:before {
	content: '';
	display: block;
	width: 60%;
	height: 60%;
	margin: 20% auto;
	border-radius: 25%;
}

.radio:checked:before {
	background: $secondPrimary50;
}

.radioNew {
	-webkit-appearance: none;
	appearance: none;
	width: 26px;
	height: 26px;
	border-radius: 5px;
	border: 1px solid $firstPrimary70;
	margin-bottom: -8px;
}

.radioNew:before {
	content: '';
	display: block;
	width: 60%;
	height: 60%;
	margin: 20% auto;
	border-radius: 25%;
}

.radioNew:checked:before {
	background: $secondPrimary50;
}

.label-profile-pic {
	position: absolute;
	bottom: 8px;
	left: 13px;
}

.img-profile-pic {
	width: 20px;
	height: 20px;
}

.input-file {
	background: $thirdNeutral;
	border-radius: 5px;
	border: 1px solid $firstNeutral80;
	outline: none;
	text-align: center;
	cursor: pointer;

	height: 84px;
}

.files-input {
	width: 220px;
	input {
		display: none;
	}
	.labelFounder {
		cursor: pointer;
	}
	.founder-preview-img {
		width: 100%;
		object-fit: cover;
		object-position: center;
	}
}

.input-file-logo {
	background: $thirdNeutral;
	border-radius: 5px;
	border: 1px solid $firstNeutral80;
	outline: none;
	text-align: center;
	cursor: pointer;

	height: 112px;
}

.checkbox-Icon {
	width: 32px;
	height: 32px;
	margin-bottom: 8px;
	opacity: 0.4;
}

.input-file-img {
	background: $thirdNeutral;
	border-radius: 5px;
	border: 1px solid $firstNeutral80;
	outline: none;
	text-align: center;
	cursor: pointer;
	width: 225px;
	height: 224px;
}

.input-file-ilustration {
	background: $thirdNeutral;
	border-radius: 5px;
	border: 1px solid $firstNeutral80;
	outline: none;
	text-align: center;
	cursor: pointer;
	width: 225px;
	height: 224px;
}

@media screen and (min-width: 768px) {
	.input-option {
		margin-top: -2px;
		// padding-top: 2px;
	}
	.input-search {
		height: 37px;
	}
	.input-search-2 {
		max-width: 426px;
		height: 40px;
	}
	.input-file {
		height: 134px;
	}
	.checkbox-Icon {
		width: 36px;
		height: 36px;
	}
	.label-profile-pic {
		position: absolute;
		bottom: 27px;
		left: 15px;
	}

	.img-profile-pic {
		width: 30px;
		height: 30px;
	}
}

@media screen and (min-width: 992px) {
	.input-search {
		height: 35px;
	}
	.input-search-2 {
		max-width: 285px;
		height: 30px;
	}
	.checkbox-Icon {
		width: 26px;
		height: 26px;
	}
}

@media screen and (min-width: 1200px) {
	.input-search {
		height: 35px;
	}
	.input-search-2 {
		max-width: 285px;
		height: 30px;
	}
	.checkbox-Icon {
		width: 28px;
		height: 28px;
	}
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border: 1px solid $firstPrimary70 !important;
}
.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'],
.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
	width: 600px !important;
}

.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'],
.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
	padding-left: 8px !important;
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}

.MuiChip-outlined {
	border: 1px solid $Ocean30 !important;
}

.MuiChip-deleteIcon {
	color: $firstPrimary70 !important;
}
.MuiChip-root {
	color: $firstPrimary70 !important;

	font-family: $robotoRegular !important;
}

.MuiAutocomplete-endAdornment {
	display: none !important;
}

.filterChecked {
	background-color: #c5e4ea;
}
