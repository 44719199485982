.justify-content-center {
	justify-content: center;
}

.align-items-center {
	align-items: center;
}

.align-content-around {
	align-content: space-around;
}

.align-items-start {
	align-items: flex-start;
}
.align-items-end {
	align-items: flex-end;
}

.justify-content-start {
	justify-content: flex-start !important;
}

.justify-content-between {
	justify-content: space-between;
}
.justify-content-around {
	justify-content: space-around;
}

.justify-content-end {
	justify-content: flex-end;
}

.flex-column {
	flex-direction: column;
}

.justify-self-start {
	justify-self: start;
}
.justify-self-center {
	justify-self: center;
}

.justify-self-end {
	justify-self: flex-end;
}
.align-self-start {
	align-self: flex-start;
}
.align-self-end {
	align-self: flex-end !important;
}

@media screen and (min-width: 768px) {
	.justify-content-md-center {
		justify-content: center;
	}

	.align-items-md-center {
		align-items: center;
	}

	.align-items-md-start {
		align-items: start;
	}

	.justify-content-md-start {
		justify-content: flex-start;
	}

	.justify-content-md-between {
		justify-content: space-between;
	}

	.justify-content-md-end {
		justify-content: flex-end;
	}

	.flex-md-row {
		flex-direction: row;
	}
}

@media screen and (min-width: 992px) {
	.justify-content-lg-center {
		justify-content: center;
	}

	.align-items-lg-center {
		align-items: center;
	}

	.align-items-lg-start {
		align-items: start;
	}

	.justify-content-lg-start {
		justify-content: flex-start;
	}

	.justify-content-lg-between {
		justify-content: space-between;
	}

	.justify-content-lg-end {
		justify-content: flex-end;
	}

	.flex-lg-column {
		flex-direction: column;
	}

	.flex-lg-row {
		flex-direction: row;
	}

	.flex-lg-row-reverse {
		flex-direction: row-reverse;
	}
}

@media screen and (min-width: 1200px) {
	.justify-content-xl-center {
		justify-content: center;
	}

	.align-items-xl-center {
		align-items: center;
	}

	.align-items-xl-start {
		align-items: start;
	}

	.justify-content-xl-start {
		justify-content: flex-start;
	}

	.justify-content-xl-between {
		justify-content: space-between;
	}

	.justify-content-xl-end {
		justify-content: flex-end;
	}
}
