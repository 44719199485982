//Font Sizes
.text-xxs {
	font-size: 10px;
}

.text-xs {
	font-size: 12px;
}
.text-small {
	font-size: 14px !important;
}

.text-normal {
	font-size: 16px;
}

.text-medium {
	font-size: 18px;
}

.text-big {
	font-size: 20px;
}

.text-big-32 {
	font-size: 32px;
}

.h3 {
	font-size: 24px;
}

.h1 {
	font-size: 36px;
}
.text-bigger {
	font-size: 48px;
}
.text-biggest {
	font-size: 100px;
}

//Font Bold

.a-text-bold-second-neutral-100 {
	color: $secondNeutral100;
	font-family: $robotoBold;
}
.a-text-bold-second-neutral-90 {
	color: $secondNeutral90;
	font-family: $robotoBold;
}
.a-text-bold-second-neutral-70 {
	color: $secondNeutral70;
	font-family: $robotoBold;
}
.a-text-bold-second-neutral-40 {
	color: $secondNeutral40;
	font-family: $robotoBold;
}

.a-text-bold-first-neutral-100 {
	color: $firstNeutral100;
	font-family: $robotoBold;
}
.a-text-bold-first-primary-60 {
	color: $firstPrimary60;
	font-family: $robotoBold;
}

.a-text-bold-gray {
	color: $gray;
	font-family: $robotoBold;
}

.a-text-bold-first-neutral-10 {
	color: $firstNeutral10;
	font-family: $robotoBold;
}

//Font Medium
.a-text-medium {
	font-family: $robotoMedium;
}

.a-text-medium-first-primary-70 {
	color: $firstPrimary70;
	font-family: $robotoMedium;
}
.a-text-medium-second-primary-70 {
	color: $secondPrimary70;
	font-family: $robotoMedium;
}

.a-text-medium-first-neutral-100 {
	color: $firstNeutral100;
	font-family: $robotoMedium;
}

.a-text-medium-first-neutral-90 {
	color: $firstNeutral90;
	font-family: $robotoMedium;
}

.a-text-medium-second-neutral-100 {
	color: $secondNeutral100;
	font-family: $robotoMedium;
}

.a-text-medium-second-neutral-90 {
	color: $secondNeutral90;
	font-family: $robotoMedium;
}

.a-text-medium-second-neutral-60 {
	color: $secondNeutral60;
	font-family: $robotoMedium;
}
.a-text-medium-second-neutral-70 {
	color: $secondNeutral70;
	font-family: $robotoMedium;
}

.a-text-medium-first-neutral-80 {
	color: $firstNeutral80;
	font-family: $robotoMedium;
}

.a-text-medium-first-neutral-50 {
	color: $firstNeutral50;
	font-family: $robotoMedium;
}

.a-text-medium-first-neutral-10 {
	color: $firstNeutral10;
	font-family: $robotoMedium;
}

.a-text-medium-second-neutral-30 {
	color: $secondNeutral40;
	font-family: $robotoMedium;
}

.a-text-medium-third-neutral {
	color: $thirdNeutral;
	font-family: $robotoMedium;
}

.a-text-regular-third-neutral {
	color: $thirdNeutral;
	font-family: $robotoRegular;
}
.a-text-medium-black {
	color: $black;
	font-family: $robotoMedium;
}

.a-text-medium-first-primary-60 {
	color: $firstPrimary60 !important;
	font-family: $robotoMedium !important;
}

.a-text-medium-gray {
	color: $gray;
	font-family: $robotoMedium;
}

.a-text-medium-first-primary-75-italic {
	color: $firstPrimary75;
	font-family: $robotoItalic;
}

//Font Regular
.a-text-regular-first-primary-60 {
	color: $firstPrimary60;
	font-family: $robotoRegular;
}
.a-text-regular-first-primary-70 {
	color: $firstPrimary70;
	font-family: $robotoRegular;
}
.a-text-regular-second-neutral-70 {
	color: $secondNeutral70;
	font-family: $robotoRegular;
}
.a-text-regular-second-neutral-60 {
	color: $secondNeutral60;
	font-family: $robotoRegular;
}
// First-neutral
.a-text-regular-first-neutral-100 {
	color: $firstNeutral100;
	font-family: $robotoRegular;
}
.a-text-regular-first-neutral-90 {
	color: $firstNeutral90;
	font-family: $robotoRegular;
}
.a-text-regular-first-neutral-80 {
	color: $firstNeutral80;
	font-family: $robotoRegular;
}
.a-text-regular-first-neutral-50 {
	color: $firstNeutral50;
	font-family: $robotoRegular;
}
.a-text-regular-second-primary-50 {
	color: $secondPrimary50;
	font-family: $robotoRegular;
}
.a-text-regular-second-neutral-30 {
	color: $secondNeutral30;
	font-family: $robotoRegular;
}
.a-text-regular-second-neutral-70 {
	color: $secondNeutral30;
	font-family: $robotoRegular;
}

.a-text-medium-gray {
	color: $gray;
	font-family: $robotoMedium;
}

.a-text-regular-first-neutral-10 {
	color: $firstNeutral10;
	font-family: $robotoRegular;
}
//Font light

.a-text-light-second-neutral-60 {
	font-family: $robotoLight;
	color: $secondNeutral60;
}

.hl-text {
	color: #355471;
}

// Second-neutral
.a-text-regular-second-neutral-100 {
	color: $secondNeutral100;
	font-family: $robotoRegular;
}
.a-text-regular-second-neutral-90 {
	color: $secondNeutral90;
	font-family: $robotoRegular;
}
.a-text-regular-second-neutral-70 {
	color: $secondNeutral70;
	font-family: $robotoRegular;
}
.a-text-regular-second-neutral-60 {
	color: $secondNeutral60;
	font-family: $robotoRegular;
}
.a-text-regular-second-neutral-40 {
	color: $secondNeutral40;
	font-family: $robotoRegular;
}

// Second-primary
.a-text-regular-second-primary-70 {
	color: $secondPrimary70;
	font-family: $robotoRegular;
}
.a-text-regular-first-primary-60 {
	color: $firstPrimary60;
	font-family: $robotoRegular;
}
.a-text-regular-second-primary-50 {
	color: $secondPrimary50;
	font-family: $robotoRegular;
}

.a-text-regular-alert-60 {
	color: $red60;
	font-family: $robotoRegular;
}

.a-text-italic-first-neutral-80 {
	color: $firstNeutral80;
	font-family: $robotoItalic;
}

@media screen and (min-width: 768px) {
	.text-md-small {
		font-size: 14px;
	}

	.text-md-normal {
		font-size: 16px !important;
	}

	.text-md-medium {
		font-size: 18px !important;
	}

	.text-md-big {
		font-size: 20px !important;
	}

	.h3-md {
		font-size: 24px !important;
	}

	.h1-md {
		font-size: 36px;
	}
}

@media screen and (min-width: 992px) {
	.text-lg-small {
		font-size: 14px;
	}

	.text-lg-normal {
		font-size: 16px !important;
	}

	.text-lg-medium {
		font-size: 18px !important;
	}

	.text-lg-big {
		font-size: 20px;
	}

	.h3-lg {
		font-size: 24px;
	}

	.h1-lg {
		font-size: 36px;
	}
}

@media screen and (min-width: 1200px) {
	.text-xl-small {
		font-size: 14px;
	}

	.text-xl-normal {
		font-size: 16px !important;
	}

	.text-xl-medium {
		font-size: 18px;
	}

	.text-xl-big {
		font-size: 20px;
	}

	.h3-xl {
		font-size: 24px;
	}

	.h1-xl {
		font-size: 36px;
	}
}
