.list-style-none {
	list-style: none;
}

@media screen and (min-width: 768px) {
	.list-style-md-none {
		list-style: none;
	}
}
@media screen and (min-width: 992px) {
	.list-style-lg-none {
		list-style: none;
	}
}
@media screen and (min-width: 1200px) {
	.list-style-xl-none {
		list-style: none;
	}
}
