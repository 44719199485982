/* Limitar los estilos solo al componente MailSent */
.mailsent-container {
  background-color: $firstNeutral20;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .card--large {
    background-color: white;
    padding: 24px;
    border-radius: 10px;
    @include dropShadow();
    max-width: 352px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
      width: 150px; /* Ajuste de tamaño para el logo */
      height: auto;
      margin-bottom: 24px; /* Espaciado debajo del logo */
    }

    .image-news {
      width: 100%;
      max-width: 249px;
      margin: 16px auto; /* Centrar imagen */
    }

    h3 {
      font-size: 24px;
      color: $secondNeutral100;
      font-family: $robotoBold;
      text-align: center;
      margin-bottom: 16px; /* Espaciado */
    }

    p {
      font-size: 16px;
      color: $secondNeutral60;
      font-family: $robotoRegular;
      text-align: center;
      margin-bottom: 16px;
    }
  }
}
