.snap-container {
  height: 100px;
  overflow: auto;  
  scroll-snap-type: y mandatory;  
}

.snap-section {
  text-align: center;
  scroll-snap-align: start;
  min-height: 60px;  
}