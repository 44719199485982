.btn-pagination{
  border: none !important;
  background-color: transparent !important;
}
.btn-pagination:focus{
   outline: transparent;
  }
.MuiPagination-ul {
    justify-content: center;
}
.MuiPaginationItem-page.Mui-selected {
    background-color:$firstPrimary50 !important;
    color:white;
    font-family: $robotoBold;
}