.profile-100hv {
	height: calc(100vh - 88px - 48px - 48px);
}

@media screen and (min-width: 992px) {
	.profile-100hv {
		min-height: calc(574px + 8px);
	}
}

@media screen and (min-width: 992px) {
	.profile-100hv {
		min-height: calc(574px + 8px);
	}
}

@media screen and (min-width: 1200px) {
	.profile-100hv {
		min-height: calc(434px + 4px);
	}
}
