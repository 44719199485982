.list-checkbox {
  border-bottom: 1px solid $firstNeutral50;
  border-top: 1px solid $firstNeutral50;
}

.list-checkbox:last-of-type {
  border-bottom: 1px solid $firstNeutral50;
  border-top: none;
}

.filter-button__image {
  width: 20px;
}
.filter-button {
  background-color: white;
  border: none;
}

#modalDownload > .MuiCircularProgress-colorPrimary {
  color: #355471 !important;
}

.background-blue {
  background-color: $firstPrimary60;
}

.padding-download {
  padding: 0px 32px;
}

.border-radius-download {
  border-radius: 10px 10px 0px 0px;
}

.width-close-download {
  width: 24px;
}

.filter-label {
  padding: 4px 16px;  
  display: inline-block;
  background: white;
  border-radius: 15px;
  border: 0.5px solid $firstPrimary60;
  outline: none;
  cursor: pointer;
}

.filter-label-icon {
  width: 12px;
  cursor: pointer;
}

.icon-pdf {
  width: 18px;
}

@media screen and (min-width: 992px) {
  .company-container {
    height: calc(100vh - 232px);
    overflow: scroll;
  }
}

.select-pdf-modal{
  position: absolute;
  width: 500%;
  left: -150%;
  top: -1280%;
  bottom: -1280%;
  background: rgba(53, 84, 113, 0.9);
}

.nav-back{
  position: absolute;
  left: 20%;
  top: 28%;
  cursor: pointer;
}

.frame-back{
  position: absolute;
  left: 21.5%;
  top: 28.2%;
  font-style: normal;
  font-size: 24px;
  color: #cccccc;
}

.p-1-text{
  position: absolute;
  left: 20%;
  top: 35%;
  font-size: 24px;
  color: #FFFFFF;
}

.p-2-text{
  position: absolute;
  right: 49.5%;
  top: 35%;
  font-size: 24px;
  color: #FFFFFF;
}

.p-1-frame-select{
  position: absolute;
  height: 400px;
  left: 20%;
  top: 38%;
  background: #F5FCFE;
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-shadow: 2px 3px 3px 1px rgba(0, 0, 0, 0.25);
  border-radius: 17.2076px;
}

.p-2-frame-select{
  position: absolute;
  height: 400px;
  right: 43%;
  top: 38%;
  background: #F5FCFE;
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-shadow: 2px 3px 3px 1px rgba(0, 0, 0, 0.25);
  border-radius: 18.664px;
}

.select-pdf-modal-m{
  position: absolute;
  width: 200%;
  left: -6.5%;
  top: -1280%;
  bottom: -1280%;
  background: rgba(53, 84, 113, 0.9);
}

.mp-1-text{
  position: absolute;
  left: 17%;
  top: 27.8%;
  font-size: 17.5px;
  color: #FFFFFF;
}

.mp-2-text{
  position: absolute;
  left: 17%;
  top: 46.3%;
  font-size: 17.5px;
  color: #FFFFFF;
}

.mp-1-frame-select{
  position: absolute;
  left: 17%;
  width: 230px;
  top: 29.5%;
  background: #F5FCFE;
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-shadow: 2px 3px 3px 1px rgba(0, 0, 0, 0.25);
  border-radius: 17.2076px;
}

.mp-2-frame-select{
  position: absolute;
  width: 230px;
  top: 48%;
  left: 17%;
  background: #F5FCFE;
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-shadow: 2px 3px 3px 1px rgba(0, 0, 0, 0.25);
  border-radius: 18.664px;
} 
