.background-first-primary-10 {
	background-color: $firstPrimary10;
	font-weight: bold;
}

.background-hover-nav:hover {
	background-color: rgba(155, 204, 221, .15);
}

.background-transparent {
	background-color: transparent !important;
}
.background-second-primary-10 {
	background-color: $secondPrimary10;
}
.background-white {
	background-color: $thirdNeutral;
}
.background-first-neutral-10 {
	background-color: $firstNeutral10;
}
.background-first-neutral-30 {
	background-color: $firstNeutral30;
}

.background-first-neutral-40 {
	background-color: $firstNeutral40;
}
.background-first-primary-60 {
	background-color: $firstPrimary60;
}

.background-second-Neutral-100 {
	background-color: $secondNeutral100;
}
.background-ocean-30 {
	background-color: #dceff3;
}
.background-second-primary-80 {
	background-color: $secondPrimary80;
}

@media screen and (min-width: 768px) {
	.background-md-first-primary-10 {
		background-color: $firstPrimary10;
	}
	.background-md-second-primary-10 {
		background-color: $secondPrimary10;
	}
	.background-md-white {
		background-color: $thirdNeutral;
	}
	.background-md-first-neutral-10 {
		background-color: $firstNeutral10;
	}
	.background-md-first-primary-60 {
		background-color: $firstPrimary60;
	}
}

@media screen and (min-width: 992px) {
	.background-lg-first-primary-10 {
		background-color: $firstPrimary10;
	}
	.background-lg-second-primary-10 {
		background-color: $secondPrimary10;
	}

	.background-lg-white {
		background-color: $thirdNeutral;
	}
	.background-lg-first-neutral-10 {
		background-color: $firstNeutral10;
	}
	.background-lg-first-primary-60 {
		background-color: $firstPrimary60;
	}
}

@media screen and (min-width: 1200px) {
	.background-xl-first-primary-10 {
		background-color: $firstPrimary10;
	}
	.background-xl-second-primary-10 {
		background-color: $secondPrimary10;
	}
	.background-xl-white {
		background-color: $thirdNeutral;
	}
	.background-xl-first-neutral-10 {
		background-color: $firstNeutral10;
	}
	.background-xl-first-primary-60 {
		background-color: $firstPrimary60;
	}
}
.MuiMobileStepper-dots {
	display: none !important;
}
