.minuta {
    &_image{
        width: 16px;
    }
    &_btn-add {
        width: 142px;
        height: 32px 
    }
    &_btn {
        width: 96px;
        height: 40px 
    }
}
.popper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
@media screen and (min-width: 768px) {
    .minuta {
        &_image{
            width: 24px;
        }
        &_btn-add {
            width: 195px;
            height: 44px 
        }
        &_btn {
            width: 132px;
        }
    }
}
@media screen and (min-width: 992px) {
    .minuta {
        &_btn-add {
            width: 155px;
            height: 30px 
        }
        &_btn {
        width: 96px;
        height: 40px 
        }
        &_pr-col-6 {
            padding-right: 32px;
        }
        &_pl-col-6 {
            padding-left: 32px;
        }
    }
}
@media screen and (min-width: 1200px) {}