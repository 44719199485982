.eye-icon {
	right: 8px;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
}
.icon-blog {
	width: 20px !important;
	height: 20px !important;
}
.img-blog {
	max-height: 200px;
	max-width: 240px;
	border-radius: 0px;
}
.image-auhtor {
	height: 50px;
	width: 50px;
	border-radius: 5px 0px 0px 5px;
}

.object-fit-scale {
	object-fit: scale-down;
}

.image-auhtor-detail {
	height: auto;
	max-height: 70px;
	width: 100px;
	border-radius: 5px 0px 0px 5px;
}

.tag-icon {
	height: 10px;
	width: 10px;
}
.person-icon {
	height: 12px;
	width: 12px;
}
.image-preview-blog-container-md {
	height: 100px !important;
	width: 100% !important;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	border: 1px solid $Ocean30;
}
.image-preview-blog-container-vertical {
	height: 100px !important;
	width: 100% !important;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	border: 1px solid $Ocean30;
}
.image-preview-blog {
	width: auto;
	height: auto;
	max-width: 190px;
	max-height: 100px;
	object-fit: cover;
	margin: auto;
}
.image-preview-blog-home {
	width: auto;
	height: auto;
	max-width: 120px;
	max-height: 100px;
	object-fit: cover;
	margin: auto;
}
.image-preview-blog-vertical {
	width: auto;
	height: auto;
	max-width: 190px;
	max-height: 100px;
	object-fit: cover;
	margin: auto;
}

.image-preview-blog-container {
	width: 500px;
	height: 150px;
	border-radius: 15px !important;
	display: flex;
	justify-content: center;

	align-items: center;
	border: 1px solid $Ocean30;
}

.icon-modal-user {
	width: 36px !important;
	height: 36px;
	border-radius: 50%;
}

.icon-header-user {
	height: 30px;
	width: 40px;
	margin-right: 10px;
	border-radius: 50%;
}

.search-icon {
	right: 8px;
	top: 50%;
	transform: translateY(-50%);
}

.closeTopics {
	position: relative;
	padding-left: 240px;
	width: 20px !important;
}
.arrow-icon {
	float: right;
	margin-right: 10px;
	margin-top: 8px;
	width: 20px;
}
.arrow-blue-icon {
	position: absolute;
	right: 11%;
}
.add-icon {
	float: right;
	position: absolute !important;
}
.img-company {
	background-color: $firstNeutral60;
	border-radius: 5px;
	min-width: 125px;
	min-height: 77px;
	align-items: center;
	display: flex;
}
.image-left {
	transform: rotate(90deg);
}
.image-right {
	transform: rotate(-90deg);
}
.image-top {
	transform: rotate(180deg);
}

.logo-company-report {
	padding: 5px;
	width: 30px;
	height: auto;
}

.menu {
	&_icon {
		height: 28px;
	}
}

.img-show {
	width: 100%;
	height: auto;
	max-height: 500px;
	border-radius: 10px;
}
.img-show-ipad {
	width: 100%;
	height: auto;
	object-fit: contain;
	border-radius: 10px;
	max-height: 800px;
}

.container-img-list {
	width: 100%;
	height: 100%;

	min-height: 200px;
}

.logo-company {
	width: 125px;
	height: 80px;
}

.nav-company-icon {
	width: 19px;
	height: 19px;
}

.list-company-icon {
	width: 20px;
	height: 20px;
}

.company-card-icon {
	width: 20px;
	height: 20px;
}

.company-card-profile-icon {
	width: 32px;
	height: 32px;
}

.minuta-icon {
	width: 24px;
	height: 24px;
}

.img-social-detalles {
	width: 24px;
}

.img-dev {
	height: 32px;
	&_spacing {
		margin: 0 46px 0 39px;
	}
}

.img-order {
	width: 16px;
}

.img-filter {
	width: 20px;
}

.icon-card-question {
	width: 32px;
	height: 32px;
	border-radius: 50%;
}
.icon-card-poper {
	width: 36px;
	height: 36px;
	border-radius: 22px !important;
}

.img-file {
	width: 130px;
	height: 84px;
	object-fit: contain;
}
.error-404 {
	width: 259px;
}

.ilustration-file {
	width: 220px;
	height: 220px;
	object-fit: contain;
}

.img-questions {
	width: 100%;
	height: 100px;
	max-height: 100px;
	margin-bottom: 12px;
	object-fit: cover;
}

.img-question-list {
	width: 100%;
	max-height: 200px;
	object-fit: cover;
}

.icon-feed-questions {
	width: 24px !important;
	height: 24px !important;
}
.img-question-list-multiple {
	width: 50%;
	height: 100px;
	object-fit: cover;
}

.img-container-list-multiple {
	width: 50%;
	height: 100px;
	object-fit: cover;
	background-color: #c4c4c4;
}

.img-question-list-multiple-item1 {
	width: 50%;
	height: 200px;

	object-fit: cover;
}

.img-container-list-multiple-item1 {
	width: 50%;
	height: 200px;
	background-color: #c4c4c4;
}

.img-preview {
	object-fit: contain;
	height: 100%;
	width: 100%;
	max-height: 500px;
}
.close-preview {
	position: absolute !important;
	right: 0;
	top: 0;
	background-color: white !important;
}

@media screen and (min-width: 768px) {
	.image-auhtor-detail {
		height: auto;
		width: 150px;
		max-height: 105px;
		border-radius: 5px 0px 0px 5px;
	}
	.img-blog {
		max-height: 500px;
		max-width: 1000px;
	}

	.closeTopics {
		position: absolute;
		right: 126px;
	}
	.icon-modal-user {
		width: 56px !important;
		height: 56px;
		border-radius: 50%;
	}
	.icon-header-user {
		height: 50px;
		width: 60px;
		margin-right: 20px;
		border-radius: 50%;
	}
	.menu {
		&_icon {
			height: 35px;
		}
	}
	.image-preview-blog {
		width: auto;
		height: auto;
		max-height: 150px;
		object-fit: cover;
		margin: auto;
	}

	.img-question-list {
		max-height: 300px;
	}

	.img-questions {
		max-height: 300px;
		height: 200px !important;
		margin-bottom: 16px;
	}
	.logo-company {
		width: 220px;
		height: 130px;
	}
	.nav-company-icon {
		width: 32px;
		height: 32px;
	}
	.minuta-icon {
		width: 40px;
		height: 40px;
	}
	.minuta-empty-state {
		width: 491px;
	}
	.error-404 {
		width: 450px;
	}
	.img-social-detalles {
		width: 40px;
	}
	.img-file {
		width: 214px;
		height: 126px;
	}
	.list-company-icon {
		width: 40px;
		height: 40px;
	}
	.icon-md-user {
		height: 56px !important;
		width: 56px !important;
	}

	.icon-md-card-question {
		height: 26px !important;
		width: 26px !important;
	}
	.img-show {
		width: 100%;
		object-fit: contain;
		max-height: 800px;
		border-radius: 10px;
	}
	.icon-feed-questions {
		width: 80px !important;
		height: 80px !important;
	}
	.img-question-list-multiple,
	.img-container-list-multiple {
		height: 180px;
	}

	.img-question-list-multiple-item1 {
		width: 50%;
		height: 100%;
		object-fit: cover;
	}
	.img-container-list-multiple-item1 {
		height: 360px;
	}
}

@media screen and (min-width: 992px) {
	.image-auhtor-detail {
		height: auto !important;
		width: 100px !important;
		max-height: 70px;

		border-radius: 5px 0px 0px 5px;
	}

	.img-blog {
		max-height: 500px;
		max-width: 600px;
	}
	.nav-company-icon {
		width: 26px;
		height: 26px;
	}

	.icon-modal-user {
		width: 36px !important;
		height: 36px;
		border-radius: 50%;
	}
	.img-question-list {
		max-height: 600px;
	}

	.minuta-icon {
		width: 32px;
		height: 32px;
	}
	.img-social-detalles {
		width: 32px;
	}
	.img-order {
		width: 25px;
	}
	.img-filter {
		width: 32px;
	}

	.img-questions {
		height: 100px !important;
	}
	.img-show {
		width: 100%;
		height: auto;
		object-fit: contain;
		min-height: 200px;
		max-width: 800px;
	}
	.icon-md-user {
		height: 36px !important;
		width: 36px !important;
	}
	.img-question-list-multiple,
	.img-container-list-multiple {
		height: 198px;
	}

	.img-question-list-multiple-item1 {
		width: 50%;
		height: inherit;
		max-height: 400px;

		object-fit: cover;
	}
	.img-container-list-multiple-item1 {
		width: 50%;

		height: 400px;
	}
	.image-empty {
		height: 200px !important;
	}
}
@media screen and (min-width: 1200px) {
	.closeTopics {
		position: relative;
		left: 440px;
	}
	.img-order {
		width: 16px;
	}

	.img-filter {
		width: 20px;
	}

	.img-show-ipad {
		width: 100%;

		border-radius: 10px;
		max-height: 600px;
	}
	.img-question-list-multiple,
	.img-container-list-multiple {
		height: 198px;
	}

	.img-question-list-multiple-item1 {
		width: 50%;
		height: inherit;

		object-fit: cover;
	}
}

@media screen and (min-width: 1300px) {
	.closeTopics {
		position: relative;
		left: 600px;
	}
	.img-order {
		width: 16px;
	}

	.img-filter {
		width: 20px;
	}

	.img-show-ipad {
		width: 100%;

		border-radius: 10px;
		max-height: 500px !important;
	}
}
