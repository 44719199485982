.separator {
  margin: 0.5rem 0.25rem;
  border: 1px solid #cdced6;
  height: 1rem;
}

.button {
  background-color: unset;
  border-radius: 5px;
  border: unset;
  padding: 0.125rem;

  &:hover {
    background-color: #e0e1e6;
  }
}

.activeButton {
  background-color: #e0e1e6;
}

.markdownToolbar {
  border: 1px solid #a8aba8;
  border-bottom: unset;
  display: flex;
  gap: 0.25rem;
  align-items: center;
  background-color: #f0f0f3;
  padding: 0.375rem;
  border-radius: 5px 5px 0 0;

  & button {
    border-radius: 5px;

    &:first-child {
      border-radius: 5px 0 0 5px;
    }

    &:nth-child(2) {
      border-radius: 0;
    }

    &:nth-child(3) {
      border-radius: 0 5px 5px 0;
    }
  }
}

.markdownContainer {
  border: 1px solid #a8aba8;
  border-top: unset;
  outline: none;
  border-radius: 0 0 5px 5px;
  padding: 0.75rem;
}