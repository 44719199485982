.containerLanding{
    background-color: $firstPrimary70;
}

.circle1{
    border-radius: 100px;
    width: 155px;
    height: 155px;
    background-color: #fff;
}

.containerCirecle{
    display: inline-flex;
    flex-direction: row;
    width: 120px;
    height: 40px;
    flex-wrap: wrap;
}

.circle2{
    border-radius: 100px;
    width: 135px;
    height: 135px;
    background-color: $thirdNeutral;
}

.containerCirecle2{
    display: inline-flex;
    flex-direction: row;
    height: 50px;
    flex-wrap: wrap;
}

.imgCircle{
    width: 80px;
    height: 80px;
}

.minicircle{
    width: 10px;
    height: 10px;
    background: $firstPrimary60;
}

.wavesLanding{
    background-color: $firstPrimary70;
    margin-top: -30px;
    z-index: 0;
}

.backgroundButtonLand{
    background-color: $thirdNeutral;
    flex-direction: row;
}

.z-index-button{
    z-index: 100;
    position: relative;
}

.buttonLanding{
    width: 217px;
    height: 60px;
}

.buhoLanding{
    position: relative;
    z-index: 1;
    bottom: 100px;
}

.textLanding{
    width: 110px;
}

.sizeBuho{
    height:15px
}

@media screen and (max-width:350px) {
    .circle2{
        border-radius: 100px;
        width: 125px;
        height: 125px;
        background-color: $thirdNeutral;
    }
    .circle1{
        border-radius: 100px;
        width: 140px;
        height: 140px;
        background-color: #fff;
    }
}