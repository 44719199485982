.switch-cid {
  @include switchButtons($firstNeutral30,$firstPrimary60,$robotoMedium)
}

.switch-cid-active {

  @include switchButtons($firstPrimary60,$firstNeutral10,$robotoMedium)
}

//cambio A LA CARD
.switch-question {
    @include switchButtons($thirdNeutral,$firstPrimary70,$robotoRegular);

    border: .93px solid rgba(210, 223, 235, 0.897);
  }
.switch-question-active {
    @include switchButtons($firstPrimary10,$firstPrimary70,$robotoRegular);
    border: .93px solid rgba(210, 223, 235,0.1);

  }


  .rounded-topic{
    @include switchButtons($thirdNeutral,$firstPrimary70,$robotoRegular);
    
  }


.home-cid {
    &_icon {
        height: 28px;
    }
    &_button {
        width: 100px;
        height: 36px;
    }
}


@media screen and (min-width: 768px) {
    .home-cid {
        &_icon {
            height: 40px;
        }
        &_button {
            width: 255px;
            height: 48px;
        }
    }
}
@media screen and (min-width: 992px) {
    .home-cid {
        &_button {
            width: 130px;
            height: 40px;
        }
    }

}
@media screen and (min-width: 1200px) {

    
}