.modal {
	@include modalStyles();
	padding: 24px;
}

.modal-preview {
	@include modalStyles();
	padding: 10px;
}

.modal-desk {
	width: 100%;
	max-width: 260px;
	padding: 24px;
	background-color: white;
	margin: auto;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	border-radius: 10px;
	outline: none;
}

.alert-modal {
	width: 100%;
	max-width: 700px;
	padding: 14px 24px 14px 24px;
	background-color: white;
	margin: auto;
	position: absolute;

	transform: translate(-50%, -50%);
	border-radius: 10px;
	outline: none;
}

.modal-pic {
	@include modalStyles();
	width: 100%;
	max-width: 174px;
	padding: 16px 45px 24px 45px;
}

.modal-confirm-follow {
	@include modalStyles();
	width: 100%;
	max-width: 220px;
	padding: 40px 37px;
}

.modal-create {
	height: 100vh;
	width: 100vw;
	background-color: white;
}

.modal-download {
	@include modalStyles();
}

.modal-response {
	height: 100vh;
	background-color: white;
}

@media screen and (min-width: 768px) {
	.modal {
		max-width: 500px;
	}
	.modal-preview {
		max-width: 600px;
	}
	.modal-pic {
		@include modalStyles();
		width: 100%;
		max-width: 329px;
		padding: 32px 32px;
	}

	.modal-confirm-follow {
		@include modalStyles();
		width: 100%;
		max-width: 318px;
		padding: 40px 37px;
	}
}
@media screen and (min-width: 992px) {
	.modal {
		max-width: 500px;
	}

	.modal-download {
		max-width: 500px;
	}

	.modal-md-width {
		max-width: 700px !important;
	}
	.modal-view-follows {
		@include modalStyles();
		width: 100%;
		max-width: 646px;
		max-height: 687px;
		padding: 20px 55px 44px 55px;
	}
	.alert-modal {
		left: 50%;
		top: 110%;
	}
	.modal-preview {
		max-width: 500px;
		max-height: 500px;
	}
}
@media screen and (min-width: 1200px) {
	.modal {
		max-width: 500px;
	}

	.modal-md-width {
		max-width: 700px !important;
	}
	.alert-modal {
		left: 50%;
		top: 112%;
	}
}
