.align-self-center {
	align-self: center;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}
.text-justified {
	text-align: justify;
}
.text-right-end {
	margin-left: auto;
	margin-right: 0;
}

.text-justified {
	text-align: justify;
}

.text-right-end {
	margin-left: auto;
	margin-right: 0;
}

.text-decoration-none {
	text-decoration: none !important;
}

.text-decoration-underline {
	text-decoration: underline;
}

.list-style-none {
	list-style: none;
}

.line-height {
	line-height: 1.5;
}

.line-height-18 {
	line-height: 18px;
}

.line-height-24 {
	line-height: 24px;
}

.line-height-21 {
	line-height: 21px;
}

.disable {
	background-color: $thirdNeutral !important;
	border-radius: 5px !important;
	border: 1px solid $firstNeutral60 !important;
	outline: none !important;
	box-shadow: none !important;
	position: initial !important;
}

.sidebar-opcion-active {
	border-left: 3px solid $firstPrimary70;
}

.roboto-regular {
	font-family: $robotoRegular;
}
.roboto-light {
	font-family: $robotoLight;
}
.sidebar-opcion-active p {
	color: $secondPrimary60;
}

.roboto-bold {
	font-family: $robotoBold;
}

.roboto-medium {
	font-family: $robotoMedium;
}

@media screen and (min-width: 768px) {
	.text-md-center {
		text-align: center;
	}
}
@media screen and (min-width: 992px) {
	.text-lg-center {
		text-align: center;
	}
}
@media screen and (min-width: 1200px) {
	.text-xl-center {
		text-align: center;
	}
}
